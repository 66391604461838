import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/header/Header.jsx";
import Footer from "../component/footer/Footer";
import { supabase } from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/system/supaclient.js";



class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceList1: [],
      serviceList2: [],
      serviceList3: [],
      serviceList4: [],
      serviziData: [], // Cambiato qui
    };
  }

  getIconByValue(value) {
    switch (value) {
      case 1:
        return <FiCast />;
      case 2:
        return <FiLayers />;
      case 3:
        return <FiUsers />;
      case 4:
        return <FiMonitor />;
      default:
        return "MWS"; // o un'icona di default se necessario
    }
  }

  componentDidMount() {
    this.fetchserviziData();
  }

  fetchserviziData = async () => {
    const { data: serviziData, error: serviziError } = await supabase
      .from("pagServizi")
      .select("*");

    if (serviziError) {
      console.log(
        "Errore nel caricamento dei dati dalla tabella 'home':",
        serviziError
      );
      return;
    }

    if (serviziData) {
      this.setState({ serviziData: serviziData }); // Aggiornato qui
    }

    try {
      const promises = [1, 2, 3, 4].map((sezione) =>
        supabase.from("servizi").select("*").eq("sezione", sezione)
      );
      const results = await Promise.all(promises);

      this.setState({
        serviceList1: results[0].data,
        serviceList2: results[1].data,
        serviceList3: results[2].data,
        serviceList4: results[3].data,
      
      });
    } catch (error) {
      console.log("Errore nel caricamento dei dati:", error);
    }
  };

  render() {
    const serviziDataWithID2 = this.state.serviziData.find((item) => item.id === 2);
    const serviziDataWithID3 = this.state.serviziData.find((item) => item.id === 3);
    const serviziDataWithID4 = this.state.serviziData.find((item) => item.id === 4);
    const serviziDataWithID5 = this.state.serviziData.find((item) => item.id === 5);
    
    return (
      <div className="active-dark">
        <React.Fragment>
          <PageHelmet pageId={2} />
          <Header
            headertransparent="header--transparent"
            colorblack="color--black"
            logoname="logo.png"
          />

          {/* Start Breadcrump Area */}
          <Breadcrumb title={"SERVIZI"} />
          {/* End Breadcrump Area */}

          {/* Start Service Area */}
          <div className="service-area ptb--120 bg_color--5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center mb--30">
                    <h2 className="title">
                      {serviziDataWithID2 ? serviziDataWithID2.titolo : ""}
                    </h2>
                    <p className="description">
                      {serviziDataWithID2 ? serviziDataWithID2.descrizione : ""}
                    </p>
                  </div>
                </div>
                <div className="row service-one-wrapper">
                  {this.state.serviceList1.map((val, i) => (
                    <div
                      className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                      key={i}
                    >
                      <a href={val.link}>
                        <div className="service service__style--2">
                          <div className="icon">
                            {" "}
                            {this.getIconByValue(val.icon)}
                          </div>
                          <div className="content">
                            <h3 className="title">{val.title}</h3>
                            <p>{val.description}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* End Service Area */}

            {/* Start Service Area */}
            <div className="service-area ptb--120 bg_color--1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title text-center mb--30">
                      <h2 className="title">
                        {serviziDataWithID3 ? serviziDataWithID3.titolo : ""}
                      </h2>
                      <p className="description">
                        {serviziDataWithID3
                          ? serviziDataWithID3.descrizione
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row service-one-wrapper">
                  {this.state.serviceList2.map((val, i) => (
                    <div
                      className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                      key={i}
                    >
                      <a href={val.link}>
                        <div className="service service__style--2">
                          <div className="icon">
                            {" "}
                            {this.getIconByValue(val.icon)}
                          </div>
                          <div className="content">
                            <h3 className="title">{val.title}</h3>
                            <p>{val.description}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* End Service Area */}

            {/* Start Service Area */}
            <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title text-center mb--30">
                      <h2 className="title">
                        {serviziDataWithID4 ? serviziDataWithID4.titolo : ""}
                      </h2>
                      <p className="description">
                        {serviziDataWithID4
                          ? serviziDataWithID4.descrizione
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row creative-service">
                  {this.state.serviceList3.map((val, i) => (
                    <div
                      className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                      key={i}
                    >
                      <a href={val.link}>
                        <div className="service service__style--2">
                          <div className="icon">
                            {" "}
                            {this.getIconByValue(val.icon)}
                          </div>
                          <div className="content">
                            <h3 className="title">{val.title}</h3>
                            <p>{val.description}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* End Service Area */}

            {/* Start Service Area */}
            <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title text-center mb--30">
                      <h2 className="title">
                        {serviziDataWithID5 ? serviziDataWithID5.titolo : ""}
                      </h2>
                      <p className="description">
                        {serviziDataWithID5
                          ? serviziDataWithID5.descrizione
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row creative-service">
                  {this.state.serviceList4.map((val, i) => (
                    <div
                      className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                      key={i}
                    >
                      <a href={val.link}>
                        <div className="service service__style--2">
                          <div className="icon">
                            {" "}
                            {this.getIconByValue(val.icon)}
                          </div>
                          <div className="content">
                            <h3 className="title">{val.title}</h3>
                            <p>{val.description}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* End Service Area */}

          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
          {/* End Back To Top */}

          <Footer />
        </React.Fragment>
      </div>
    );
    }
}
export default Service;
import React, { Component } from "react";
import { supabase } from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/system/supaclient.js";


class Bloghome extends Component {
    constructor(props) {
    super(props);
    this.state = {
      blogList: [],
      homeData: []
    };
  }
 
  componentDidMount() {
    this.fetchblogData();
  }

  fetchblogData = async () => {
    const { data: homeData, error: homeError } = await supabase
      .from("home")
      .select("*");

    if (homeError) {
      console.log(
        "Errore nel caricamento dei dati dalla tabella 'home':",
        homeError
      );
      return; 
    }

    if (homeData) {
      this.setState({ homeData: homeData }); // Aggiornato qui
    }

    const { data, error } = await supabase
      .from("blog")
      .select("*")
      .eq("evidenza", true)
      .order("order", { ascending: true });
    if (error) {
      console.log("Errore nel caricamento dei dati:", error);
      return;
    }
    if (data) {
      this.setState({ blogList: data });
    }
  };

  render() {
     const homeDataWithID7 = this.state.homeData.find(
       (item) => item.id === 7
     );

    return (
      <React.Fragment>
        <div className="rn-blog-area pt--20 bg_color--1 mb-dec--30">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="section-title text-left">
                  <h2> {homeDataWithID7 ? homeDataWithID7.titolo : ""}</h2>
                  <p>{homeDataWithID7 ? homeDataWithID7.descrizione : ""}</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="blog-btn text-left text-lg-right mt--10 mb--10 ">
                  <a className="btn-transparent rn-btn-dark" href="/blog">
                    <span className="text">Tutti gli articoli</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              {this.state.blogList.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <a href={value.link}>
                        <img
                          className="w-100"
                          src={value.image}
                          alt={value.title}
                        />
                      </a>
                    </div>
                    <div className="content">
                      <p className="blogtype">{value.category}</p>
                      <h4 className="title">
                        <a href={value.link}>{value.title}</a>
                      </h4>
                      <div className="blog-btn">
                        <a className="rn-btn text-white" href={value.link}>
                          Leggi
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Bloghome;
             
             

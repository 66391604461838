import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import BrandTwo from "../elements/BrandTwo";
import { supabase } from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/system/supaclient.js";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/header/Header.jsx";
import Footer from "../component/footer/Footer";


 
class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutData: [],
    };
  }

  componentDidMount() {
    this.fetchAboutData();
  }

  fetchAboutData = async () => {
    const { data: aboutData, error: aboutError } = await supabase
      .from("home")
      .select("*");

    if (aboutError) {
      console.log(
        "Errore nel caricamento dei dati dalla tabella 'home':",
        aboutError
      );
      return;
    }

    if (aboutData) {
      this.setState({ aboutData: aboutData }); // Aggiornato qui
    }
  };

  render() {
      const aboutDataWithID2 = this.state.aboutData.find(
        (item) => item.id === 2
      );
      const aboutDataWithID3 = this.state.aboutData.find(
        (item) => item.id === 3
      );
      const aboutDataWithID4 = this.state.aboutData.find(
        (item) => item.id === 4
      );
    return (
      <div className="active-dark">
        <React.Fragment>
          <PageHelmet pageId={24} />

          <Header
            headertransparent="header--transparent"
            colorblack="color--black"
            logoname="logo.png"
          />
          {/* Start Breadcrump Area */}
          <Breadcrumb title={"About"} />
          {/* End Breadcrump Area */}

          {/* Start About Area  */}
          <div className="rn-about-area ptb--120 bg_color--1">
            <div className="rn-about-wrapper">
              <div className="container">
                <div className="row row--35 align-items-center">
                  <div className="col-lg-5">
                    <div className="thumbnail">
                      <img
                        className="w-100"
                        src="/assets/images/about/about-1.jpg"
                        alt="About Images"
                      />
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="about-inner inner">
                      <div className="section-title">
                        <h2 className="title">
                          {aboutDataWithID2 ? aboutDataWithID2.titolo : ""}
                        </h2>
                        <p className="description">
                          {aboutDataWithID2 ? aboutDataWithID2.descrizione : ""}
                        </p>
                      </div>
                      <div className="row mt--30">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <div className="about-us-list">
                            <h3 className="title">
                              {aboutDataWithID3 ? aboutDataWithID3.titolo : ""}
                            </h3>
                            <p>
                              {aboutDataWithID3
                                ? aboutDataWithID3.descrizione
                                : ""}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <div className="about-us-list">
                            <h3 className="title">
                              {aboutDataWithID4 ? aboutDataWithID4.titolo : ""}
                            </h3>
                            <p>
                              {" "}
                              {aboutDataWithID4
                                ? aboutDataWithID4.descrizione
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End About Area  */}

          {/* Start CounterUp Area */}
          <div className="rn-counterup-area pb--120 bg_color--1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center">
                    <h3 className="fontWeight500">Numeri</h3>
                  </div>
                </div>
              </div>
              <CounterOne />
            </div>
          </div>
          {/* End CounterUp Area */}

          {/* Start Finding Us Area  */}
          <div className="rn-finding-us-area rn-finding-us bg_color--1">
            <div className="inner">
              <div className="content-wrapper">
                <div className="content">
                  <h4 className="theme-gradient">ANGELO MONTINI </h4>
                  <p>
                    Professionista specializzato nel settore della tecnologia, è
                    esperto di creazione di prodotti digitali. Ha lavorato con
                    clienti in diverse regioni d'Italia in progetti che vanno
                    dallo sviluppo web alla creazione di campagne di marketing,
                    dal design all'analisi delle prestazioni e
                    all'ottimizzazione dei processi. <br />
                    Con una specializzazione post lauream in Sviluppo Front End,
                    ha un background solido in tecnologia e informatica, ha
                    plasmato la sua carriera intraprendendo un percorso che lo
                    ha portato a diventare un professionista affermato nei
                    settori IT e Marketing.
                    <br />
                    Angelo ha fondato l'agenzia{" "}
                    <strong>MONTINI WEB STRATEGY</strong> nel 2012 per offrire
                    consulenza in ambito digitale.
                  </p>
                  <a
                    className="rn-btn btn-white"
                    href="https://docs.google.com/document/d/1zCNgna3EgGZXnhPbIAhm9P8Y71tGsdb8lOywuUsw9o8/pub"
                    style={{ margin: ".3em", padding: ".3em" }}
                  >
                    CV aggiornato
                  </a>

                  <a
                    className="rn-btn btn-white"
                    href="https://www.cloudskillsboost.google/public_profiles/3bfe79fa-fbcf-42ba-ba01-82df5ad93921"
                    style={{ margin: ".3em", padding: ".3em" }}
                  >
                    Certificazioni Google Cloud
                  </a>
                  <a
                    className="rn-btn btn-white"
                    href="https://www.linkedin.com/in/angelomontini/"
                    style={{ margin: ".3em", padding: ".3em" }}
                  >
                    LinkedIn
                  </a>
                </div>
              </div>
              <div className="thumbnail">
                <div className="image">
                  <img src="/assets/images/bg/7.png" alt="Finding Images" />
                </div>
              </div>
            </div>
          </div>
          {/* End Finding Us Area  */}

          {/* Start Brand Area */}
          <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <BrandTwo />
                </div>
              </div>
            </div>
          </div>
          {/* End Brand Area */}

          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
          {/* End Back To Top */}

          <Footer />
        </React.Fragment>
      </div>
    );
  }
}
export default About
// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';


// Dark Home Layout 
import DarkMainDemo from './dark/MainDemo';


// Element Layout
import Portfolio from "./elements/Portfolio";
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Prezzi from "./elements/prezzi";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";

// Servizi Singoli
import AppAziendali from "./elements/servizi/app-aziendali.jsx";
import ContentSeo from "./elements/servizi/content-seo";
import Ecommerce from "./elements/servizi/ecommerce";
import GoogleWork from "./elements/servizi/google-work";
import Landing from "./elements/servizi/landing";
import LocalSeo from "./elements/servizi/local-seo";
import Pwa from "./elements/servizi/pwa";
import SeoTecnica from "./elements/servizi/seo-tecnica";
import SitiHackerati from "./elements/servizi/siti-hackerati";
import SitiWeb from "./elements/servizi/siti-web";
import Cookies from "./elements/servizi/cookies";
import Privacy from "./elements/servizi/privacy";
import PrivacyAi from "./elements/servizi/app-privacy";
import TerminiAi from "./elements/servizi/termini";



// Posts Layout
import BlogCodice from "./elements/post/codice";
import BlogGaeu from "./elements/post/gaeu";
import BlogGworkspace from "./elements/post/gworkspace";
import BlogHinton from "./elements/post/hinton";
import BlogMetadati from "./elements/post/metadati";
import BlogPwa from "./elements/post/pwa";
import BlogRetineurali from "./elements/post/retineurali";
import BlogSeo from "./elements/post/seo-content";
import BlogFuturo from "./elements/post/sitifuturo";

// Blocks Layout

import Team from "./blocks/Team";
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";

import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
require("dotenv").config();



class Root extends Component{
    render(){
        return (
          <BrowserRouter basename={"/"}>
            <PageScrollTop>
              <Switch>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/`}
                  component={DarkMainDemo}
                />
                {/* Element Layot */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/service`}
                  component={Service}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/service-details`}
                  component={ServiceDetails}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/contact`}
                  component={Contact}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/about`}
                  component={About}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/prezzi`}
                  component={Prezzi}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/portfolio-details`}
                  component={PortfolioDetails}
                />
                {/* Servizi singoli  */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/servizi/app-aziendali`}
                  component={AppAziendali}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/servizi/content-seo`}
                  component={ContentSeo}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/servizi/ecommerce`}
                  component={Ecommerce}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/servizi/google-work`}
                  component={GoogleWork}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/servizi/landing`}
                  component={Landing}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/servizi/local-seo`}
                  component={LocalSeo}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/servizi/pwa`}
                  component={Pwa}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/servizi/seo-tecnica`}
                  component={SeoTecnica}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/servizi/siti-hackerati`}
                  component={SitiHackerati}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/servizi/siti-web`}
                  component={SitiWeb}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/servizi/cookies`}
                  component={Cookies}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/servizi/privacy`}
                  component={Privacy}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/servizi/app-privacy`}
                  component={PrivacyAi}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/servizi/termini`}
                  component={TerminiAi}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog`}
                  component={Blog}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog-details`}
                  component={BlogDetails}
                />

                {/* Post */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/codice`}
                  component={BlogCodice}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/gaeu`}
                  component={BlogGaeu}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/gworkspace`}
                  component={BlogGworkspace}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/hinton`}
                  component={BlogHinton}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/pwa`}
                  component={BlogPwa}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/metadati`}
                  component={BlogMetadati}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/retineurali`}
                  component={BlogRetineurali}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/seo-content`}
                  component={BlogSeo}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/sitifuturo`}
                  component={BlogFuturo}
                />

                {/* Blocks Elements  */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/team`}
                  component={Team}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/counters`}
                  component={Counters}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/testimonial`}
                  component={Testimonial}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/portfolio`}
                  component={Portfolio}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/video-popup`}
                  component={VideoPopup}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/gallery`}
                  component={Gallery}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/clint-logo`}
                  component={Brand}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/progressbar`}
                  component={ProgressBar}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/contact-form`}
                  component={ContactForm}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/google-map`}
                  component={GoogleMap}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/columns`}
                  component={Columns}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/pricing-table`}
                  component={PricingTable}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/404`}
                  component={error404}
                />
                <Route component={error404} />
              </Switch>
            </PageScrollTop>
          </BrowserRouter>
        );
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { supabase } from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/system/supaclient.js";

function PageHelmet(props) {
  const [metaData, setMetaData] = useState(null);
  const [isHomePage, setIsHomePage] = useState(false);

  useEffect(() => {
    const fetchPortfolioData = async () => {
      try {
        const { data: metaData, error } = await supabase
          .from("meta")
          .select("*")
          .eq("id", props.pageId);

        if (error) {
          console.error("Errore nel caricamento dei dati:", error);
          return;
        }

        if (metaData && metaData.length > 0) {
          setMetaData(metaData[0]);
          setIsHomePage(props.pageId === 1);
        }
      } catch (error) {
        console.error("Si è verificato un errore:", error);
      }
    };

    fetchPortfolioData();
  }, [props.pageId]); // Dipendenze: pageId

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://cmp.osano.com/169kLpTvDuBSD3Avo/e0fa3cf0-013c-4004-bf3b-ab13e8734e30/osano.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []); // Questo useEffect verrà eseguito solo una volta


  return (
    <React.Fragment>
      <Helmet>
        <title>{metaData ? metaData.title : "Caricamento..."}</title>
        <meta
          name="description"
          content={metaData ? metaData.description : ""}
        />
   

        {isHomePage && (
          <link rel="preload" href="/assets/images/homeweb.webp" as="image" />
        )}

        {/* Inserimento dello script Matomo (Google Tag Manager) */}
        <script>
          {`
            var _paq = window._paq = window._paq || [];
            /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
            (function() {
              var u="//esempio.angelomontini.com/";
              _paq.push(['setTrackerUrl', u+'matomo.php']);
              _paq.push(['setSiteId', '36']);
              var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
              g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
            })();
          `}
        </script>
        {/* Fine script Matomo */}
      </Helmet>
    </React.Fragment>
  );
}

export default PageHelmet;

import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";
import { supabase } from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/system/supaclient.js";


class ServiceTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceList: [],
      serviceData: {}, 
    };
  } 

  getIconByValue(value) {
    switch (value) {
      case 1:
        return <FiCast />;
      case 2:
        return <FiLayers />;
      case 3:
        return <FiUsers />;
      case 4:
        return <FiMonitor />;
      default:
        return "MWS"; // o un'icona di default se necessario
    }
  }

  componentDidMount() {
    this.fetchServiceData();
  }

  fetchServiceData = async () => {
    const { data: serviceData, error: serviceError } = await supabase
      .from("home")
      .select("*")
      .eq("id", 5);

    if (serviceError) {
      console.log(
        "Errore nel caricamento dei dati dalla tabella 'home':",
        serviceError
      );
      return;
    }

    if (serviceData) {
      this.setState({ serviceData: serviceData[0] }); // Aggiornato qui
    }

    const { data, error } = await supabase
      .from("servizi")
      .select("*")
      .eq("evidenza", true);
    if (error) {
      console.log("Errore nel caricamento dei dati:", error);
      return;
    }
    if (data) {
      this.setState({ serviceList: data });
    }
  };


  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
              <h2 className="title">
                {" "}
                {this.state.serviceData ? this.state.serviceData.titolo : ""}
              </h2>
              <p>
                {" "}
                {this.state.serviceData
                  ? this.state.serviceData.descrizione
                  : ""}
              </p>
              <div className="service-btn">
                <a className="btn-transparent rn-btn-dark" href="/service">
                  <span className="text">Elenco dei servizi</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-12 mt_md--50">
            <div className="row service-one-wrapper">
              {this.state.serviceList.map((val, i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                  <a href={val.link}>
                    <div className="service service__style--2">
                      <div className="icon">
                        {" "}
                        {this.getIconByValue(val.icon)}
                      </div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceTwo;

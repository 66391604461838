import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";
import { supabase } from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/system/supaclient.js";
import Searchai from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/header/searchAI.jsx";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
        menuList: [],
        };
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }



  componentDidMount() {
    this.fetchPortfolioData();
  }

  fetchPortfolioData = async () => {
    const { data, error } = await supabase.from("menu").select("*");
    if (error) {
      console.log("Errore nel caricamento dei dati:", error);
      return;
    }
    if (data) {
      this.setState({
        menuList: data.sort((a, b) => a.ordine - b.ordine),
      });
    }
  };

  render() {
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { color = "default-color" } = this.props;

    return (
      <header
        className={`header-area formobile-menu header--transparent ${color}`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">
                <img src="/assets/images/logo/logo.png" alt="Logo" />
              </a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                {this.state.menuList.map((value, index) => (
                  <li key={index}>
                    <Link to={value.pagina}>{value.titolo}</Link>
                  </li>
                ))}

                <li>
                  <a
                    href="https://g.page/r/CfIhz7D3MUikEBM/review"
                    style={{
                      color: "#ad1138",
                    }}
                  >
                    recensioni
                  </a>
                </li>
                <li>
                  <a
                    href="https://wa.me/393392528986?text=Salve,%20vorrei%20sapere%20di%20più%20sui%20servizi%20offerti"
                    style={{
                      color: "green",
                    }}
                  >
                    <span>WhatsApp</span>
                  </a>
                </li>
              </ul>
            </nav>
            <div className="header-btn">
              <Searchai />
            </div>

           
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}


export default Header;

import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";

import BlogList from "../elements/blog/BlogList";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/header/Header.jsx";
import Footer from "../component/footer/Footer";

class Blog extends Component{
    render(){
        return (
          <div className="active-dark">
            <React.Fragment>
              <PageHelmet pageId={14} />

              <Header
                headertransparent="header--transparent"
                colorblack="color--black"
                logoname="logo.png"
              />
              {/* Start Breadcrump Area */}
              <Breadcrumb title={"BLOG"} />
              {/* End Breadcrump Area */}

              {/* Start Blog Area */}
              <div className="rn-blog-area ptb--120 bg_color--1">
                <div className="container">
                  <BlogList />
                </div>
              </div>
              {/* End Blog Area */}

              {/* Start Back To Top */}
              <div className="backto-top">
                <ScrollToTop showUnder={160}>
                  <FiChevronUp />
                </ScrollToTop>
              </div>
              {/* End Back To Top */}

              <Footer />
            </React.Fragment>
          </div>
        );
    }
}
export default Blog;

import React, { Component } from "react";
import PageHelmet from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/common/Helmet.jsx";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/header/Header.jsx";
import Footer from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/footer/Footer.jsx";
import ServiceTwo from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/elements/service/ServiceTwo.jsx";

class Privacy extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageId={26} />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image"
          style={{
            opacity: "1",
            backgroundImage: `url("/assets/images/bg/12.png")`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inner rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    Privacy Policy Assitente AI{" "}
                  </h2>
                  <ul className="page-list">
                    <li className="breadcrumb-item">
                      <Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
                    </li>

                    <li className="breadcrumb-item active">
                      {" "}
                      AI Privacy Policy
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-12 col-12">
                        <div class="details mt_md--30 mt_sm--30">
                          <div>
                            <h5>
                              <strong>
                                Informativa ai sensi del Regolamento UE 2016/679{" "}
                              </strong>

                              <br />

                              <small>
                                Ultimo aggiornamento: 20 agosto 2024
                              </small>
                            </h5>
                            <p>
                              Questa Privacy Policy descrive come vengono
                              raccolti, utilizzati e protetti i dati personali
                              degli utenti che interagiscono con{" "}
                              <b>l'assistente virtuale AI </b>integrato nel sito
                              web <a href="/">www.angelomontini.com</a> ,
                              sviluppato per fornire un'esperienza
                              personalizzata e interattiva.
                            </p>
                            <br />
                            <h5>Raccolta dei Dati</h5>
                            <p>
                              In riferimento al trattamento dei dati personali
                              degli utenti che lo consultano. Si tratta di
                              un'informativa che è resa ai sensi del Regolamento
                              Europeo 2016/679 dettato in materia di protezione
                              delle persone fisiche con riguardo al trattamento
                              dei dati personali, nonché alla libera
                              circolazione di tali dati (di seguito anche
                              Regolamento) per gli utenti dei servizi del nostro
                              sito, che vengono erogati via internet.
                              L'assistente virtuale raccoglie automaticamente
                              informazioni limitate e necessarie per garantire
                              un'interazione efficace e personalizzata.
                            </p>
                            <br />
                            <h5>Tipologie di dati raccolti </h5>
                            <p>
                              Le tipologie di dati raccolti possono includere:
                              <ul>
                                <li>
                                  <p>
                                    Dati di Interazione: Informazioni
                                    riguardanti le domande e le risposte
                                    scambiate durante l'interazione con il
                                    chatbot.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    Dati Tecnici: Informazioni sul dispositivo,
                                    il browser e il sistema operativo utilizzato
                                    per accedere al Sito.
                                  </p>
                                </li>
                              </ul>
                            </p>

                            <h5>Utilizzo dei Dati</h5>
                            <p>
                              Il Titolare del trattamento dei dati personali,
                              relativi a persone identificate o identificabili
                              trattati a seguito della consultazione del nostro
                              sito è Angelo Montini P.iva 11822551005 CF
                              MNTNGL73C16H501P Via Portuense 743 00148 Roma ph.
                              339 2528986
                            </p>

                            <p>
                              I dati raccolti vengono utilizzati per i seguenti
                              scopi:{" "}
                              <ul>
                                Fornire risposte contestuali e personalizzate:
                                <li>
                                  Il chatbot utilizza i dati per comprendere e
                                  rispondere alle richieste dell'utente in modo
                                  più efficace.
                                </li>
                                <li>
                                  Migliorare il Servizio: I dati di interazione
                                  sono utilizzati per migliorare continuamente
                                  l'accuratezza e la pertinenza delle risposte,
                                  attraverso l'apprendimento automatico e
                                  l'analisi delle conversazioni passate.
                                </li>
                                <li>
                                  Gestione tecnica: I dati tecnici sono
                                  utilizzati per garantire il corretto
                                  funzionamento del Sito e dell'assistente
                                  virtuale.
                                </li>
                              </ul>
                            </p>
                            <h5>Luogo, finalità e comunicazione dei dati</h5>
                            <p>
                              I trattamenti connessi ai servizi web di questo
                              sito hanno luogo presso la predetta sede della
                              società e sono curati da personale incaricato del
                              trattamento. I dati possono essere trattati da
                              società che per nostro conto si occupano della
                              gestione informatica (compreso l’hosting) del
                              sito.
                            </p>
                            <h5>Tecnologie Utilizzate</h5>
                            <p>
                              L'assistente virtuale utilizza le seguenti
                              tecnologie:{" "}
                              <ul>
                                <li>
                                  <h5>Dialogflow:</h5> Utilizzato per
                                  l'elaborazione del linguaggio naturale e la
                                  gestione delle interazioni complesse.{" "}
                                </li>
                                <li>
                                  <h5>React.js: </h5>Utilizzato per
                                  l'integrazione e la gestione del frontend del
                                  Sito.{" "}
                                </li>
                                <li>
                                  <h5>Google Cloud: </h5>Utilizzato per
                                  l'archiviazione sicura dei dati, la
                                  scalabilità del servizio e la gestione delle
                                  risorse backend.
                                </li>
                              </ul>
                            </p>
                            <h5>Conservazione dei Dati</h5>
                            <p>
                              I dati personali raccolti sono conservati solo per
                              il tempo necessario a soddisfare gli scopi sopra
                              descritti, nel rispetto delle leggi vigenti. I
                              dati possono essere conservati per un periodo più
                              lungo qualora richiesto dalla legge.
                            </p>
                            <br />
                            <h5>Condivisione dei Dati</h5>
                            <p>
                              I dati personali non vengono condivisi con terze
                              parti, ad eccezione dei fornitori di servizi
                              tecnici necessari per il funzionamento del Sito e
                              dell'assistente virtuale, come Dialogflow e Google
                              Cloud, che trattano i dati in conformità con le
                              normative sulla privacy applicabili.
                            </p>
                            <br />

                            <h5>Sicurezza dei Dati</h5>
                            <p>
                              Adottiamo misure di sicurezza tecniche e
                              organizzative appropriate per proteggere i dati
                              personali degli utenti da accessi non autorizzati,
                              perdite, distruzioni o divulgazioni non
                              autorizzate.
                            </p>
                            <br />
                            <h5>Diritti degli Utenti</h5>
                            <p>
                              In relazione ai trattamenti dei suoi dati
                              personali svolti tramite questo sito, in ogni
                              momento, in qualità di interessato potrà
                              esercitare i diritti previsti dal Regolamento UE
                              2016/679 scrivendo all’indirizzo e-mail{" "}
                              <a href="mailto:posta@angelomontini.com">
                                posta@angelomontini.com
                              </a>
                              .
                              <br /> <br />
                              In particolare, potrà:
                              <br />
                              <ul>
                                <li>
                                  <p>
                                    Accedere ai propri dati personali: accedere
                                    ai suoi dati personali, ottenendo evidenza
                                    delle finalità perseguite da parte del
                                    Titolare, delle categorie di dati coinvolti,
                                    dei destinatari a cui gli stessi possono
                                    essere comunicati, del periodo di
                                    conservazione applicabile, dell’esistenza di
                                    processi decisionali automatizzati, compresa
                                    la profilazione, e, almeno in tali casi,
                                    informazioni significative sulla logica
                                    utilizzata, nonché l'importanza e le
                                    conseguenze possibili per l'interessato, ove
                                    non già indicato nel testo di questa{" "}
                                    <i>Informativa</i>
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    Correggere o eliminare i propri dati
                                    personali: Chiedere la rettifica o la
                                    cancellazione dei dati personali.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    Revocare il consenso: Revocare il consenso
                                    al trattamento dei dati personali in
                                    qualsiasi momento.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    Reclami:ove lo ritenga opportuno, proporre
                                    reclamo all'autorità di controllo.
                                  </p>
                                </li>
                              </ul>
                              Per esercitare questi diritti, gli utenti possono
                              contattare il responsabile della protezione dei
                              dati attraverso l'email fornita nel Sito.
                            </p>
                            <br />
                            <h5>Modifiche alla Privacy Policy</h5>
                            <p>
                              Questa Privacy Policy può essere aggiornata
                              periodicamente. Gli utenti verranno informati di
                              eventuali modifiche tramite un avviso sul Sito. Si
                              consiglia di consultare regolarmente questa pagina
                              per rimanere aggiornati su eventuali cambiamenti.
                            </p>
                            <br />
                            <h5>Contatti</h5>
                            <p>
                              Per ulteriori informazioni sulla presente Privacy
                              Policy o sul trattamento dei dati personali, è
                              possibile contattare il responsabile della
                              protezione dei dati all'indirizzo email:{" "}
                              <a href="mailto:posta@angelomontini.com">
                                posta@angelomontini.com
                              </a>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Service Area  */}
        <div className="service-area ptb--80  bg_image bg_image--3">
          <div className="container">
            <ServiceTwo />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}

export default Privacy;

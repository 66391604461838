import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return (
          <React.Fragment>
            <ul className="brand-style-2">
              <li>
                <img src="/assets/images/brand/1.png" alt="Logo processwire" />
              </li>
              <li>
                <img src="/assets/images/brand/2.png" alt="Logo html & css" />
              </li>
              <li>
                <img src="/assets/images/brand/3.png" alt="Logo Google cloud" />
              </li>
              <li>
                <img
                  src="/assets/images/brand/4.png"
                  alt="Logo Gogle partner"
                />
              </li>
              <li>
                <img src="/assets/images/brand/5.png" alt="Logo PHP" />
              </li>
              <li>
                <img src="/assets/images/brand/6.png" alt="Logo framework js" />
              </li>
              <li>
                <img src="/assets/images/brand/7.png" alt="Logo node" />
              </li>
              <li>
                <img src="/assets/images/brand/8.png" alt="Logo mysql" />
              </li>
              <li>
                <img src="/assets/images/brand/9.png" alt="Logo javascript" />
              </li>
              <li>
                <img src="/assets/images/brand/10.png" alt="Logo javascript" />
              </li>
            </ul>
          </React.Fragment>
        );
    }
}
export default BrandTwo;
import React, { Component } from "react";
import PageHelmet from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/common/Helmet.jsx";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/header/Header.jsx";
import Footer from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/footer/Footer.jsx";
import ServiceTwo from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/elements/service/ServiceTwo.jsx";

class Privacy extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageId={26} />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image"
          style={{
            opacity: "1",
            backgroundImage: `url("/assets/images/bg/12.png")`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inner rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    Termini di utilizzo Assitente AI{" "}
                  </h2>
                  <ul className="page-list">
                    <li className="breadcrumb-item">
                      <Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
                    </li>

                    <li className="breadcrumb-item active">
                      {" "}
                      Termini di utilizzo
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-12 col-12">
                        <div class="details mt_md--30 mt_sm--30">
                          <div>
                            <h5>
                              <strong>
                                Termini di Servizio per il Chatbot AI Sviluppato
                                con Dialogflow{" "}
                              </strong>

                              <br />

                              <small>
                                Ultimo aggiornamento: 20 agosto 2024
                              </small>
                            </h5>
                            <p class="c4">
                              <span class="c0">
                                1. Accettazione dei Termini
                              </span>
                            </p>
                            <p class="c4">
                              <span class="c0">
                                L&#39;uso del chatbot comporta
                                l&#39;accettazione dei presenti termini di
                                servizio. Gli utenti sono tenuti a leggere e
                                comprendere questi termini prima di interagire
                                con il chatbot.
                              </span>
                            </p>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0 start">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c0">
                                  Esempio: Se inizi a usare un chatbot su un
                                  sito web di assistenza clienti cliccando sul
                                  pulsante &quot;Inizia Chat&quot;, accetti
                                  automaticamente di seguire questi termini. Se
                                  non sei d&#39;accordo con questi termini,
                                  dovresti interrompere l&#39;uso del chatbot.
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <p class="c4">
                              <span class="c0">&nbsp;2. Uso Consentito</span>
                            </p>
                            <p class="c4">
                              <span class="c0">
                                Il chatbot &egrave; destinato a specifiche
                                finalit&agrave;, come l&#39;assistenza clienti o
                                la fornitura di informazioni generali. &Egrave;
                                vietato l&#39;uso del chatbot per
                                attivit&agrave; illegali, dannose, offensive o
                                ingannevoli.
                              </span>
                            </p>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c0">
                                  Esempio Positivo: Un utente pu&ograve;
                                  chiedere al chatbot di un negozio online di
                                  aiutarlo a trovare un prodotto, ad esempio,
                                  &quot;Quali smartphone avete in offerta?&quot;
                                </span>
                              </li>
                            </ul>
                            <p class="c2">
                              <span class="c0"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c0">
                                  Esempio Negativo: &Egrave; proibito usare il
                                  chatbot per inviare spam o raccogliere
                                  informazioni personali di altri utenti. Ad
                                  esempio, un utente non dovrebbe chiedere
                                  &quot;Dammi gli indirizzi email degli altri
                                  clienti&quot;.
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <p class="c4">
                              <span class="c0">
                                &nbsp;3. Raccolta e Utilizzo dei Dati
                              </span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c0">
                                  Dati Raccolti: Il chatbot pu&ograve;
                                  raccogliere informazioni personali e altre
                                  informazioni generate dall&#39;interazione con
                                  l&#39;utente, come testi di conversazione e
                                  dati di contatto.
                                </span>
                              </li>
                            </ul>
                            <p class="c2">
                              <span class="c0"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c0">
                                  Finalit&agrave; dell&#39;Uso dei Dati: I dati
                                  raccolti possono essere utilizzati per
                                  migliorare i servizi offerti, per analisi
                                  statistiche, per personalizzare
                                  l&#39;esperienza dell&#39;utente o per altre
                                  finalit&agrave; descritte nella nostra
                                  <a href="/servizi/app-privacy">
                                    &nbsp; Privacy Policy del chatbot
                                  </a>
                                </span>
                              </li>
                            </ul>
                            <p class="c2">
                              <span class="c0"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c0">
                                  Conformit&agrave; alla Privacy: La gestione
                                  dei dati avviene in conformit&agrave; con le
                                  normative applicabili in materia di protezione
                                  dei dati, come il GDPR.
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c0">
                                  Esempio: Durante la conversazione, il chatbot
                                  potrebbe chiederti il tuo nome e il tuo
                                  indirizzo email per aiutarti a risolvere un
                                  problema con il tuo account. Questi dati
                                  saranno utilizzati solo per assisterti e
                                  migliorare il servizio, come descritto nella
                                  nostra politica sulla{" "}
                                  <a href="/servizi/app-privacy">
                                    &nbsp; Privacy Policy del chatbot
                                  </a>
                                  .
                                </span>
                              </li>
                            </ul>
                            <p class="c2">
                              <span class="c0"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c7">
                                  Esempio di Conformit&agrave;: Se un utente
                                  residente in Europa chiede al chatbot di
                                  cancellare tutti i dati associati al proprio
                                  account, il servizio rispetter&agrave; tale
                                  richiesta in conformit&agrave; con il GDPR.
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <p class="c4">
                              <span class="c1">
                                4. Integrazione con Dialogflow &nbsp;
                              </span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c7">Dialogflow</span>
                                <span class="c1">
                                  : Questo chatbot &egrave; sviluppato
                                  utilizzando Dialogflow, una piattaforma di
                                  Google. Pertanto, l&#39;uso del chatbot
                                  &egrave; soggetto anche ai termini di servizio
                                  e alla
                                  <a href="https://cloud.google.com/privacy">
                                    &nbsp; Privacy Policy di Google
                                  </a>{" "}
                                  &nbsp;
                                </span>
                              </li>
                            </ul>
                            <p class="c2">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Accesso ai Dati: Google potrebbe avere accesso
                                  a determinati dati generati
                                  dall&#39;interazione con il chatbot, secondo i
                                  termini di Dialogflow. &nbsp;
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Esempio: Poich&eacute; il chatbot utilizza
                                  Dialogflow di Google, quando fai una domanda
                                  come &quot;Quando sar&agrave; disponibile il
                                  nuovo modello di scarpe?&quot;, le tue parole
                                  potrebbero essere inviate a Google per
                                  l&#39;elaborazione. I dati della tua
                                  interazione saranno gestiti secondo i termini
                                  e le condizioni di Google.
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <p class="c4">
                              <span class="c1">
                                5. Responsabilit&agrave; e Garanzie &nbsp;
                              </span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Accuratezza delle Risposte: Il chatbot
                                  &egrave; progettato per fornire risposte
                                  accurate, ma non garantisce la correttezza o
                                  completezza delle informazioni fornite.
                                  L&#39;utente deve sempre verificare le
                                  informazioni prima di prendere decisioni
                                  basate su di esse. &nbsp;
                                </span>
                              </li>
                            </ul>
                            <p class="c2">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Limitazione delle Garanzie: Il servizio viene
                                  fornito &quot;cos&igrave;
                                  com&#39;&egrave;&quot; senza garanzie di alcun
                                  tipo, esplicite o implicite. &nbsp;
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Esempio: Se chiedi al chatbot informazioni sui
                                  requisiti per ottenere un prestito e il
                                  chatbot ti fornisce informazioni errate o
                                  incomplete, &egrave; importante confermare
                                  tali informazioni con un consulente umano. Il
                                  chatbot non garantisce la totale accuratezza
                                  delle risposte. &nbsp;
                                </span>
                              </li>
                            </ul>
                            <p class="c2">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Esempio di Limitazione: Se il chatbot
                                  consiglia un prodotto che non soddisfa le tue
                                  aspettative, il fornitore del servizio non
                                  sar&agrave; responsabile per la tua esperienza
                                  insoddisfacente.
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <p class="c4">
                              <span class="c1">
                                6. Limitazione della Responsabilit&agrave;
                                &nbsp;
                              </span>
                            </p>
                            <p class="c4">
                              <span class="c1">
                                Il fornitore del servizio non sar&agrave;
                                responsabile per eventuali danni diretti,
                                indiretti, incidentali o consequenziali
                                derivanti dall&#39;uso del chatbot, inclusi ma
                                non limitati a perdita di dati o danni
                                economici. &nbsp;
                              </span>
                            </p>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Esempio: Se il chatbot presenta un errore
                                  tecnico e non &egrave; disponibile per alcune
                                  ore, causando inconvenienti per gli utenti, il
                                  fornitore del servizio non sar&agrave;
                                  responsabile per eventuali perdite economiche
                                  che gli utenti potrebbero subire.
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <p class="c4">
                              <span class="c1">
                                7. Propriet&agrave; Intellettuale &nbsp;
                              </span>
                            </p>
                            <p class="c4">
                              <span class="c1">
                                Tutti i contenuti, le risposte generate e la
                                tecnologia sottostante il chatbot sono di
                                propriet&agrave; del fornitore del servizio e/o
                                di Google, secondo i termini di Dialogflow.{" "}
                              </span>
                            </p>
                            <p class="c4">
                              <span class="c1">&nbsp;</span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  &Egrave; vietato riprodurre, distribuire o
                                  creare opere derivate dai contenuti del
                                  chatbot senza l&#39;autorizzazione esplicita
                                  del proprietario. &nbsp;
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Esempio: Se il chatbot genera una risposta
                                  molto utile e l&#39;utente desidera
                                  condividerla su un blog, deve ottenere il
                                  permesso del fornitore del servizio prima di
                                  farlo. Ad esempio, un&#39;azienda che vuole
                                  utilizzare le risposte generate dal chatbot
                                  per creare una guida deve prima ottenere
                                  l&#39;autorizzazione. &nbsp;
                                </span>
                              </li>
                            </ul>
                            <p class="c2">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Esempio di Restrizione: L&#39;utente non
                                  pu&ograve; copiare e distribuire il codice
                                  sorgente del chatbot o crearne una versione
                                  modificata senza permesso.
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <p class="c4">
                              <span class="c1">
                                8. Modifiche al Servizio &nbsp;
                              </span>
                            </p>
                            <p class="c4">
                              <span class="c1">
                                Il fornitore si riserva il diritto di
                                modificare, sospendere o interrompere il chatbot
                                in qualsiasi momento, senza preavviso. &nbsp;
                              </span>
                            </p>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Esempio: Il fornitore potrebbe decidere di
                                  aggiornare il chatbot con nuove
                                  funzionalit&agrave;, come il supporto per
                                  nuove lingue. Queste modifiche potrebbero
                                  essere implementate senza preavviso, e il
                                  servizio potrebbe essere temporaneamente non
                                  disponibile durante l&#39;aggiornamento.
                                  &nbsp;
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Esempio di sospensione: Se il chatbot viene
                                  temporaneamente sospeso per manutenzione,
                                  l&#39;utente potrebbe vedere un messaggio come
                                  &quot;Il servizio non &egrave; disponibile al
                                  momento, riprova pi&ugrave; tardi&quot;.
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <p class="c4">
                              <span class="c1">
                                9. Risoluzione delle Controversie &nbsp;
                              </span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Eventuali controversie saranno risolte secondo
                                  le leggi della giurisdizione competente,
                                  specificata nei termini di servizio. &nbsp;
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Esempio: Se un utente ha una controversia
                                  relativa all&#39;uso del chatbot, come la
                                  presunta divulgazione non autorizzata dei suoi
                                  dati, la controversia sar&agrave; risolta in
                                  conformit&agrave; con le leggi dello stato
                                  specificato nei termini di servizio, come ad
                                  esempio lo stato di New York. Se previsto,
                                  l&#39;utente potrebbe dover accettare di
                                  risolvere la disputa attraverso
                                  l&#39;arbitrato anzich&eacute; in tribunale.
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <p class="c4">
                              <span class="c1">
                                10. Modifiche ai Termini &nbsp;
                              </span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Il fornitore del servizio pu&ograve;
                                  modificare i presenti termini in qualsiasi
                                  momento. Gli utenti saranno informati di
                                  eventuali modifiche significative. L&#39;uso
                                  continuato del chatbot dopo tali modifiche
                                  implica l&#39;accettazione dei nuovi termini.
                                  &nbsp;
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Esempio: Se il fornitore decide di aggiornare
                                  i termini per includere nuove regole
                                  sull&#39;uso del chatbot, gli utenti che
                                  continuano a utilizzare il servizio dopo la
                                  pubblicazione delle modifiche accettano
                                  implicitamente i nuovi termini. Ad esempio, se
                                  viene aggiunta una clausola sulla raccolta di
                                  nuovi tipi di dati, gli utenti verranno
                                  informati e potranno scegliere di accettare o
                                  interrompere l&#39;uso del chatbot.
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <p class="c4">
                              <span class="c1">
                                11. Contatti e Supporto &nbsp;
                              </span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Per domande o problemi relativi al chatbot o
                                  ai termini di servizio, gli utenti possono
                                  contattare il supporto all&#39;indirizzo
                                  <a href="mailto:posta@angelomontini.com">
                                    posta@angelomontini.com
                                  </a>{" "}
                                  &nbsp;
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Esempio: Se hai un problema con il chatbot,
                                  come non ricevere risposte adeguate, puoi
                                  contattare il supporto tramite l&#39;indirizzo
                                  email{" "}
                                  <a href="mailto:posta@angelomontini.com">
                                    posta@angelomontini.com
                                  </a>{" "}
                                  per ricevere assistenza. &nbsp;
                                </span>
                              </li>
                            </ul>
                            <p class="c2">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Esempio di Richiesta: Se desideri conoscere
                                  meglio come vengono utilizzati i tuoi dati,
                                  puoi inviare una richiesta al team di supporto
                                  e riceverai le informazioni necessarie in
                                  conformit&agrave; con la privacy policy.
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <p class="c4">
                              <span class="c1">
                                12. Disposizioni Finali &nbsp;
                              </span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Separabilit&agrave;: Se una qualsiasi parte di
                                  questi termini viene considerata invalida o
                                  non applicabile, le restanti disposizioni
                                  rimarranno in pieno vigore ed efficacia.
                                  &nbsp;
                                </span>
                              </li>
                            </ul>
                            <p class="c2">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Intero Accordo: Questi termini costituiscono
                                  l&#39;intero accordo tra l&#39;utente e il
                                  fornitore del servizio riguardo all&#39;uso
                                  del chatbot. &nbsp;
                                </span>
                              </li>
                            </ul>
                            <p class="c4 c5">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Esempio di Separabilit&agrave;: Se un
                                  tribunale decide che una parte dei termini
                                  &egrave; illegale o inapplicabile, ad esempio
                                  una clausola sulla limitazione di
                                  responsabilit&agrave;, questa parte
                                  sar&agrave; rimossa, ma il resto dei termini
                                  continuer&agrave; ad essere efficace e
                                  applicabile. &nbsp;
                                </span>
                              </li>
                            </ul>
                            <p class="c2">
                              <span class="c1"></span>
                            </p>
                            <ul class="c3 lst-kix_i4a220ka2lcd-0">
                              <li class="c4 c6 li-bullet-0">
                                <span class="c1">
                                  Esempio di Intero Accordo: Questi termini
                                  rappresentano l&#39;intero accordo tra te e il
                                  fornitore del servizio riguardo all&#39;uso
                                  del chatbot. Se hai accettato questi termini,
                                  qualsiasi altra comunicazione o accordo
                                  precedente relativo all&#39;uso del chatbot
                                  &egrave; considerato nullo e privo di effetto.
                                </span>
                              </li>
                            </ul>
                            <br />
                            <h5>Contatti</h5>
                            <p>
                              Per ulteriori informazioni sulla presente Policy o
                              sul trattamento dei dati personali, è possibile
                              contattare il responsabile della protezione dei
                              dati all'indirizzo email:{" "}
                              <a href="mailto:posta@angelomontini.com">
                                posta@angelomontini.com
                              </a>{" "}
                              oppure la{" "}
                              <a href="/servizi/app-privacy">
                                Privacy Assistente Virtuale
                              </a>{" "}
                              o la{" "}
                              <a href="/servizi/privacy">
                                Privacy Policy del sito web
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Service Area  */}
        <div className="service-area ptb--80  bg_image bg_image--3">
          <div className="container">
            <ServiceTwo />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}

export default Privacy;

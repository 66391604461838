import React, { Component ,Fragment } from "react";
import { supabase } from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/system/supaclient.js";

 
class BLogList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogList: [],
      loadedItems: 0,
      itemsToLoad: 6,
      hasMore: true,
    };
  }

  componentDidMount() {
    this.fetchblogData();
  }

  fetchblogData = async () => {
    const { data: homeData, error: homeError } = await supabase
      .from("home")
      .select("*");

    if (homeError) {
      console.log(
        "Errore nel caricamento dei dati dalla tabella 'home':",
        homeError
      );
      return;
    }

    if (homeData) {
      this.setState({ homeData: homeData }); 
    }

    const { loadedItems, itemsToLoad} = this.state;

    const { data, error } = await supabase
      .from("blog")
      .select("*")
      .order("order", { ascending: true})
      .range(loadedItems, loadedItems + itemsToLoad - 1);

    if (error) {
      console.log("Errore nel caricamento dei dati:", error);
      return;
    }

    this.setState((prevState) => ({
      blogList: [...prevState.blogList, ...data],
      loadedItems: prevState.loadedItems + data.length,
      hasMore: data.length === itemsToLoad,
    }));
  };
  render() {
    return (
      <Fragment>
        <div className="row">
          {this.state.blogList.map((value, i) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
              <div className="blog blog-style--1">
                <div className="thumbnail">
                  <a href={value.link}>
                    <img
                      className="w-100"
                      src={value.image}
                      alt={value.title}
                    />
                  </a>
                </div>
                <div className="content">
                  <p className="blogtype">{value.category}</p>
                  <h4 className="title">
                    <a href={value.link}>{value.title}</a>
                  </h4>
                  <div className="blog-btn">
                    <a className="rn-btn text-white" href={value.link}>
                      Leggi
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="view-more-btn mt--60 text-center">
              {this.state.hasMore && (
                <button
                  onClick={this.fetchblogData}
                  className="rn-button-style--2 btn-solid"
                >
                  Load More
                </button>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default BLogList;
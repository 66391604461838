import React, { Component } from "react";
import PageHelmet from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/common/Helmet.jsx";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/header/Header.jsx";
import Footer from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/footer/Footer.jsx";

import ServiceTwo from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/elements/service/ServiceTwo.jsx";


class Cookies extends Component {
 
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageId={27} />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image"
          style={{
            opacity: "1",
            backgroundImage: `url("/assets/images/bg/12.png")`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inner rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Cookies Policy</h2>
                  <ul className="page-list">
                    <li className="breadcrumb-item">
                      <Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
                    </li>

                    <li className="breadcrumb-item active"> Cookies Policy</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-12 col-12">
                        <div class="details mt_md--30 mt_sm--30">
                          <p>
                            <strong>Cosa fanno i Cookie?</strong>
                          </p>
                          <p>
                            Un&nbsp;
                            <a href="https://www.webopedia.com/TERM/C/cookie.html">
                              cookie
                            </a>
                            &nbsp;(chiamato anche Internet o Web cookie) è il
                            termine dato per descrivere un tipo di messaggio che
                            viene dato a un&nbsp;
                            <a href="https://www.webopedia.com/TERM/B/browser.html">
                              browser Web
                            </a>
                            &nbsp;da un&nbsp;
                            <a href="https://www.webopedia.com/TERM/W/Web_server.html">
                              server Web
                            </a>
                            . Lo scopo principale di un cookie è identificare
                            gli utenti e possibilmente preparare pagine Web
                            personalizzate o salvare informazioni di login al
                            sito per te.
                          </p>
                          <p>
                            Quando entri in un&nbsp;
                            <a href="https://www.webopedia.com/TERM/W/web_site.html">
                              sito Web
                            </a>
                            &nbsp;che utilizza i cookie, potresti essere chiesto
                            di compilare un modulo fornendo informazioni
                            personali; come il tuo nome, indirizzo e-mail e
                            interessi. Queste informazioni vengono impacchettate
                            in un cookie e inviate al tuo browser Web, che poi
                            memorizza le informazioni per un uso successivo. La
                            volta successiva che vai sullo stesso sito Web, il
                            tuo browser invierà il cookie al server Web. Il
                            messaggio viene inviato di nuovo al server ogni
                            volta che il browser richiede una pagina dal server.
                          </p>
                          <p>
                            Un server Web non ha memoria, quindi il sito Web
                            ospitato che stai visitando trasferisce un file di
                            cookie del browser sul disco rigido del tuo computer
                            in modo che il sito Web possa ricordare chi sei e le
                            tue preferenze. Questo scambio di messaggi consente
                            al server Web di utilizzare queste informazioni per
                            presentarti pagine Web personalizzate. Quindi, ad
                            esempio, invece di vedere solo una pagina di
                            benvenuto generica potresti vedere una pagina di
                            benvenuto con il tuo nome su di essa.
                          </p>
                          <p>
                            <strong>Tipi di Cookie</strong>
                          </p>
                          <p>
                            <strong>
                              <a href="https://www.webopedia.com/TERM/S/session_cookie.html">
                                session cookie
                              </a>
                            </strong>
                            <br />
                            Anche chiamato cookie transitorio, un cookie che
                            viene cancellato quando chiudi il browser Web. Il
                            cookie di sessione viene memorizzato nella memoria
                            temporanea e non viene mantenuto dopo la chiusura
                            del browser. I cookie di sessione non raccolgono
                            informazioni dal tuo computer. Di solito memorizzano
                            informazioni sotto forma di un'identificazione di
                            sessione che non identifica personalmente l'utente.
                          </p>
                          <br />
                          <br />
                          <p>
                            <strong>
                              <a href="https://www.webopedia.com/TERM/P/persistent_cookie.html">
                                persistent cookie
                              </a>
                            </strong>
                            <br />
                            Anche chiamato cookie permanente, o cookie
                            memorizzato, un cookie che viene memorizzato sul tuo
                            disco rigido fino alla sua scadenza (i cookie
                            persistenti sono impostati con date di scadenza) o
                            fino a quando non elimini il cookie. I cookie
                            persistenti sono usati per raccogliere informazioni
                            identificative sull'utente, come il comportamento di
                            navigazione sul Web o le preferenze dell'utente per
                            un sito Web specifico.
                          </p>
                          <p>
                            <strong>
                              Quali informazioni memorizza un Cookie?
                            </strong>
                          </p>
                          <p>
                            Per la maggior parte un cookie conterrà una stringa
                            di testo che contiene informazioni sul browser. Per
                            funzionare, un cookie non ha bisogno di sapere da
                            dove vieni, ha solo bisogno di ricordare il tuo
                            browser. Alcuni siti Web utilizzano i cookie per
                            memorizzare informazioni più personali su di te.
                            Tuttavia, ciò può essere fatto solo se tu stesso hai
                            fornito al sito Web quelle informazioni personali.
                            Siti Web legittimi cripteranno queste informazioni
                            personali memorizzate nel cookie per prevenire l'uso
                            non autorizzato da parte di un'altra parte che ha
                            accesso alla tua cartella dei cookie.
                          </p>
                          <p>
                            I cookie hanno sei parametri che possono essere
                            passati ad essi:
                          </p>
                          <ul>
                            <li>Il nome del cookie.</li>
                            <li>Il valore del cookie.</li>
                            <li>
                              La data di scadenza del cookie – ciò determina
                              quanto tempo il cookie rimarrà attivo nel tuo
                              browser.
                            </li>
                            <li>
                              Il percorso per cui il cookie è valido – ciò
                              imposta il percorso URL per cui il cookie è
                              valido. Le pagine Web al di fuori di quel percorso
                              non possono utilizzare il cookie.
                            </li>
                            <li>
                              Il dominio per cui il cookie è valido. Ciò rende
                              il cookie accessibile alle pagine su qualsiasi dei
                              server quando un sito utilizza più server in un
                              dominio.
                            </li>
                            <li>
                              La necessità di una connessione sicura – ciò
                              indica che il cookie può essere utilizzato solo
                              sotto una condizione di server sicuro, come un
                              sito che utilizza&nbsp;
                              <a href="https://www.webopedia.com/TERM/S/SSL.html">
                                SSL
                              </a>
                              .
                            </li>
                          </ul>
                          <p>
                            <strong>Cosa sono i Cookie Maliziosi?</strong>
                          </p>
                          <p>
                            I cookie normalmente non compromettono la sicurezza,
                            ma c'è una crescente tendenza di cookie maliziosi.
                            Questi tipi di cookie possono essere utilizzati per
                            memorizzare e tracciare la tua attività online. I
                            cookie che monitorano la tua attività online sono
                            chiamati cookie maliziosi o di tracciamento. Questi
                            sono i cookie cattivi da monitorare, perché
                            tracciano te e le tue abitudini di navigazione, nel
                            tempo, per costruire un profilo dei tuoi interessi.
                            Una volta che quel profilo contiene abbastanza
                            informazioni, c'è una buona possibilità che le tue
                            informazioni possano essere vendute a un'azienda
                            pubblicitaria che poi utilizza queste informazioni
                            del profilo per indirizzarti con annunci
                            pubblicitari specifici per i tuoi interessi.
                            Molti&nbsp;
                            <a href="https://www.webopedia.com/TERM/A/antivirus_program.html">
                              programmi antivirus
                            </a>
                            &nbsp;oggi segnaleranno sospetti&nbsp;
                            <a href="https://www.webopedia.com/TERM/S/spyware.html">
                              spyware
                            </a>
                            &nbsp;o&nbsp;
                            <a href="https://www.webopedia.com/TERM/A/adware.html">
                              adware
                            </a>
                            &nbsp;cookie durante la scansione del sistema alla
                            ricerca di virus.
                          </p>
                          <p>Visualizzazione &amp; Rimozione dei Cookie</p>
                          <p>
                            I cookie sono memorizzati dal browser Web sul disco
                            rigido del tuo sistema, e puoi visualizzarli per
                            vedere quali siti Web che visiti sono associati ai
                            tuoi file di cookie.
                          </p>
                          <p>
                            Se utilizzi&nbsp;
                            <a href="https://www.webopedia.com/TERM/I/Internet_Explorer.html">
                              Internet Explorer
                            </a>
                            , ad esempio seleziona Strumenti poi scegli Opzioni
                            Internet. Nella scheda generale vedrai una sezione
                            intitolata Cronologia Esplorazioni. Clicca su
                            Impostazioni poi scegli Visualizza File.
                          </p>
                          <p>
                            Questo aprirà una finestra di Esplora Risorse che
                            elenca tutti i tuoi file Internet temporanei,
                            inclusi i tuoi cookie. Ogni cookie sarà identificato
                            da un URL del sito, rendendo facile determinare
                            quali cookie ti fidi e desideri mantenere e quali
                            non ricordi di aver visitato in un sito web e quindi
                            elimineresti.
                          </p>
                          <p>
                            Per modificare le impostazioni dei cookie, basta
                            tornare in Strumenti e scegliere Opzioni Internet.
                            Nella scheda Privacy vedrai una barra scorrevole che
                            puoi muovere per regolare il livello al quale il tuo
                            browser accetta i cookie. Basso, ad esempio, blocca
                            i cookie di terze parti che non dispongono di una
                            politica di privacy compatta e limita i cookie di
                            terze parti che salvano informazioni che possono
                            essere utilizzate per contattarti senza il tuo
                            consenso. Medio Alto farà lo stesso ma bloccherà
                            anche i cookie di prima parte che salvano
                            informazioni su di te. Altre opzioni di privacy che
                            puoi scegliere sarebbero accettare tutti i cookie o
                            bloccare tutti i cookie.
                          </p>
                          <p>
                            Se stai utilizzando un browser diverso da Internet
                            Explorer, puoi visitare le seguenti pagine sui
                            cookie in ciascun sito web del browser per scoprire
                            come gestire i tuoi cookie quando usi Firefox, Opera
                            o Safari.
                          </p>
                          <ul>
                            <li>
                              Firefox:&nbsp;
                              <a href="http://mozilla.gunnars.net/firefox_help_firefox_cookie_tutorial.html#Firefox_Cookie_Manager">
                                Aiuto di Firefox: Opzioni dei Cookie di Firefox
                              </a>
                            </li>
                            <li>
                              Opera:&nbsp;
                              <a href="http://www.opera.com/support/service/security/">
                                Sicurezza, Privacy e Cookie in Opera
                              </a>
                            </li>
                            <li>
                              Safari:&nbsp;
                              <a href="http://docs.info.apple.com/article.html?artnum=151261">
                                Aiuto di Safari Gestione dei cookie
                              </a>
                            </li>
                          </ul>
                          <p>
                            <strong>Cookie di Prima e Terza Parte</strong>
                          </p>
                          <p>
                            Quando scegli un'impostazione di privacy nel tuo
                            browser, due termini che vedrai sono "cookie di
                            prima parte" e "cookie di terza parte". I cookie di
                            prima parte sono quei cookie che provengono da (o
                            vengono inviati a) il sito web che stai attualmente
                            visualizzando. Questi tipi di cookie di solito
                            conterranno informazioni sulle tue preferenze per
                            quel particolare sito web. Questi cookie sono
                            solitamente I cookie di terza parte provengono da (o
                            saranno inviati a) un sito web che non è il sito che
                            stai visitando. Ad esempio, se il sito web su cui ti
                            trovi utilizza pubblicità di terze parti, questi
                            siti web di pubblicità di terze parti potrebbero
                            utilizzare un cookie per tracciare le tue abitudini
                            web a scopi di marketing.
                          </p>
                          <p>
                            Anche se alcuni potrebbero semplicemente scegliere
                            di bloccare tutti i cookie, navigare nel web può
                            diventare difficile se lo fai. Ad esempio, se fai
                            acquisti online, molti carrelli della spesa
                            e-commerce che sono stati implementati con i cookie
                            non funzioneranno. I siti che visiti frequentemente
                            che ti permettono di personalizzare i contenuti non
                            mostreranno le tue preferenze quando li visiti se
                            elimini o disabiliti quel cookie.
                          </p>
                          <p>
                            La maggior parte dei cookie, nonostante alcune idee
                            sbagliate, sono file legittimi e non invaderanno la
                            tua privacy. Una volta che ti abitui a rivedere i
                            cookie associati al tuo browser e a gestirli da solo
                            eliminando i cookie dannosi o provando diverse
                            impostazioni di privacy del browser, puoi comunque
                            mantenere i buoni cookie che rendono la navigazione
                            facile, ma tenere lontani i cattivi cookie che
                            potrebbero tracciare le tue abitudini di navigazione
                            dal tuo sistema.
                          </p>
                          <p>
                            <strong>Lo Sapevi...</strong>
                            <br />
                            Il nome cookie deriva da oggetti UNIX chiamati
                            cookie magici. Questi sono token che vengono
                            allegati a un utente o programma e cambiano a
                            seconda delle aree entrate dall'utente o dal
                            programma.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Service Area  */}
        <div className="service-area ptb--80  bg_image bg_image--3">
          <div className="container">
            <ServiceTwo />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Cookies;

import React, { useEffect } from "react";

const SearchWidget = () => {
  useEffect(() => {
    // Carica lo script solo una volta quando il componente viene montato
    const script = document.createElement("script");
    script.src = "https://cloud.google.com/ai/gen-app-builder/client?hl=it";
    script.async = false;
    document.body.appendChild(script);

    return () => {
      // Rimuovi lo script quando il componente viene smontato
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* Button che apre il widget al clic */}
      <button className="rn-btn" id="searchWidgetTrigger">
        Search AI
      </button>

      {/* Widget di ricerca nascosto */}
      <gen-search-widget
        configId="4c71ac21-8764-4122-952c-a6742b115a1a"
        triggerId="searchWidgetTrigger"
      ></gen-search-widget>
    </div>
  );
};

export default SearchWidget;

import React, { Component } from "react";
import PageHelmet from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/common/Helmet.jsx";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/header/Header.jsx";
import Footer from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/footer/Footer.jsx";
import { supabase } from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/system/supaclient.js";
import ServiceTwo from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/elements/service/ServiceTwo.jsx";

class Ecommerce extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servizioData: {},
    };
  }
  componentDidMount() {
    this.fetchServiceData();
  }

  fetchServiceData = async () => {
    const { data: serviceData, error: serviceError } = await supabase
      .from("servizi")
      .select("*")
      .eq("id", 3);

    if (serviceError) {
      console.log(
        "Errore nel caricamento dei dati dalla tabella 'home':",
        serviceError
      );
      return;
    }

    if (serviceData) {
      this.setState({ serviceData: serviceData[0] }); // Aggiornato qui
    }
  };

  render() {
    return (
      <div className="active-dark">
        <React.Fragment>
          <PageHelmet pageId={5} />
          {/* End Pagehelmet  */}

          <Header
            headertransparent="header--transparent"
            colorblack="color--black"
            logoname="logo.png"
          />

          {/* Start Breadcrump Area */}
          <div
            className="rn-page-title-area pt--120 pb--190 bg_image"
            style={{
              opacity: "0.7",
              backgroundImage: `url(${
                this.state.serviceData && this.state.serviceData.bg
              })`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcrumb-inner rn-page-title text-center pt--100">
                    <h2 className="title theme-gradient">
                      {this.state.serviceData
                        ? this.state.serviceData.title
                        : ""}
                    </h2>
                    <ul className="page-list">
                      <li className="breadcrumb-item">
                        <Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
                      </li>

                      <li className="breadcrumb-item">
                        <Link to={`${process.env.PUBLIC_URL}/service`}>
                          Servizi
                        </Link>
                      </li>

                      <li className="breadcrumb-item active">
                        {" "}
                        {this.state.serviceData
                          ? this.state.serviceData.title
                          : ""}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrump Area */}

          {/* Start Page Wrapper */}
          <div className="rn-service-details ptb--120 bg_color--5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="service-details-inner">
                    <div className="inner">
                      {/* Start Single Area */}
                      <div className="row sercice-details-content pb--80 align-items-center">
                        <div className="col-lg-6 col-12">
                          <div className="thumb">
                            <img
                              className="w-100"
                              src={
                                this.state.serviceData
                                  ? this.state.serviceData.imageuno
                                  : ""
                              }
                              alt="Service Images"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div className="details mt_md--30 mt_sm--30">
                            <p
                              style={{
                                color: `#fff`,
                              }}
                            >
                              {this.state.serviceData
                                ? this.state.serviceData.body
                                : ""}
                            </p>

                            <h4 className="title">
                              {" "}
                              {this.state.serviceData
                                ? this.state.serviceData.title
                                : ""}
                            </h4>
                            <p
                              style={{
                                color: `#fff`,
                              }}
                            >
                              {this.state.serviceData
                                ? this.state.serviceData.description
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* End Single Area */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Page Wrapper */}

          {/* Start Service Area  */}
          <div className="service-area ptb--80  bg_image bg_image--3">
            <div className="container">
              <ServiceTwo />
            </div>
          </div>
          {/* End Service Area  */}

          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
          {/* End Back To Top */}

          <Footer />
        </React.Fragment>
      </div>
    );
  }
}
export default Ecommerce;

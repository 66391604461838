import React, { Component } from "react";
import PageHelmet from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/common/Helmet.jsx";
import { supabase } from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/system/supaclient.js";
import { FiClock, FiUser, FiTag } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Bloghome from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/elements/Bloghome.jsx";
import Header from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/header/Header.jsx";
import Footer from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/footer/Footer.jsx";

class BlogGworkspace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postData: {},
    };
  }
  componentDidMount() {
    this.fetchpostData();
  }

  fetchpostData = async () => {
    const { data: postData, error: postError } = await supabase
      .from("blog")
      .select("*")
      .eq("id", 9);

    if (postError) {
      console.log(
        "Errore nel caricamento dei dati dalla tabella 'home':",
        postError
      );
      return;
    }

    if (postData) {
      this.setState({ postData: postData[0] }); // Aggiornato qui
    }
    console.log(postData);
  };

  render() {
    return (
      <React.Fragment>
        <PageHelmet pageId={21} />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image"
          style={{
            filter: "brightness(60%)",
            backgroundImage: `url(${this.state.postData.copertina})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    {this.state.postData ? this.state.postData.title : ""}
                  </h2>
                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    <li>
                      <FiClock />
                      {this.state.postData ? this.state.postData.date : ""}
                    </li>
                    <li>
                      <FiTag />
                      {this.state.postData ? this.state.postData.category : ""}
                    </li>
                    <li>
                      <FiUser />
                      {this.state.postData ? this.state.postData.autore : ""}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <p>{this.state.postData ? this.state.postData.body : ""}</p>

                    <blockquote
                      className="rn-blog-quote"
                      style={{
                        minHeight: "200px",
                      }}
                    >
                      {this.state.postData ? this.state.postData.quote : ""}
                    </blockquote>
                    <p>
                      {this.state.postData ? this.state.postData.body2 : ""}
                    </p>
                    <div className="blog-single-list-wrapper d-flex flex-wrap">
                      <div className="thumbnail">
                        <img
                          className="w-100"
                          src={this.state.postData.image}
                          alt={
                            this.state.postData ? this.state.postData.title : ""
                          }
                        />
                        <span>
                          Autore post:{" "}
                          {this.state.postData
                            ? this.state.postData.autore
                            : ""}
                        </span>
                      </div>
                      <div className="content">
                        <h4 className="title">
                          {this.state.postData ? this.state.postData.title : ""}
                        </h4>
                        <p>
                          {this.state.postData ? this.state.postData.body3 : ""}
                        </p>
                      </div>
                      <blockquote
                        className="rn-blog-quote"
                        style={{
                          minHeight: "200px",
                        }}
                      >
                        {this.state.postData ? this.state.postData.quote : ""}
                      </blockquote>
                      <p>
                        {this.state.postData ? this.state.postData.body4 : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}
        {/* Start Blog Area */}
        <div className="rn-blog-area pb--120 bg_color--1 mb-dec--30">
          <div className="container">
            <Bloghome />
          </div>
        </div>
        {/* End Blog Area */}
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default BlogGworkspace;

import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";

import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/header/Header.jsx";
import Footer from "../component/footer/Footer"; 
import { supabase } from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/system/supaclient.js";


class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolioList: [],
      loadedItems: 0,
      itemsToLoad: 9,
      hasMore: true,
    };
  }
 
  componentDidMount() {
    this.fetchPortfolioData();
  }

  fetchPortfolioData = async () => {
    const { data: homeData, error: homeError } = await supabase
      .from("home")
      .select("*");

    if (homeError) {
      console.log(
        "Errore nel caricamento dei dati dalla tabella 'home':",
        homeError
      );
      return;
    }

    if (homeData) {
      this.setState({ homeData: homeData }); // Aggiornato qui
    }

    const { loadedItems, itemsToLoad} = this.state;

    const { data, error } = await supabase
      .from("portfolio")
      .select("*")
      .order("quando", { ascending: false })
      .range(loadedItems, loadedItems + itemsToLoad - 1);

    if (error) {
      console.log("Errore nel caricamento dei dati:", error);
      return;
    }

    this.setState((prevState) => ({
      portfolioList: [...prevState.portfolioList, ...data],
      loadedItems: prevState.loadedItems + data.length,
      hasMore: data.length === itemsToLoad, 
    }));
  };

  render() {
    return (
      <div className="active-dark">
        <React.Fragment>
          <PageHelmet pageId={13} />
          <Header
            headertransparent="header--transparent"
            colorblack="color--black"
            logoname="logo.png"
          />
          {/* Start Breadcrump Area */}
          <Breadcrumb title={"Portfolio"} />
          {/* End Breadcrump Area */}

          {/* Start Portfolio Area */}
          <div className="portfolio-area ptb--120 bg_color--1">
            <div className="portfolio-sacousel-inner">
              <div className="container">
                <div className="row">
                  {this.state.portfolioList.map((value, index) => (
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12"
                      key={index}
                    >
                      <div className="portfolio text-left mt--40">
                        <div className="thumbnail-inner">
                          <div
                            className="thumbnail"
                            style={{
                              backgroundImage: `url(${value.image})`,
                            }}
                          ></div>
                          <div
                            className="bg-blr-image"
                            style={{
                              backgroundImage: `url(${value.image})`,
                            }}
                          ></div>
                        </div>
                        <div className="content">
                          <div className="inner">
                            <p>{value.category}</p>
                            <h4 className="title">
                              <a href={value.link}>{value.title}</a>
                            </h4>
                            <ul>
                              <li>
                                <p>{value.quando}</p>
                              </li>
                              <li>
                                <p>{value.dove}</p>
                              </li>
                              <li>
                                <p>{value.settore}</p>
                              </li>
                            </ul>
                            <div className="portfolio-button">
                              <a className="rn-btn" href={value.link}>
                                Link
                              </a>
                            </div>
                          </div>
                        </div>
                        <a
                          className="link-overlay"
                          href={value.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="view-more-btn mt--60 text-center">
                      {this.state.hasMore && (
                        <button
                          onClick={this.fetchPortfolioData}
                          className="rn-button-style--2 btn-solid"
                        >
                          Load More
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Portfolio Area */}

          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
          {/* End Back To Top */}

          <Footer />
        </React.Fragment>
      </div>
    );
  }
}
export default Portfolio;
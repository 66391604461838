import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import Breadcrumb from "../elements/common/Breadcrumb";
import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/header/Header.jsx";
import Footer from "../component/footer/Footer";



class Contact extends Component{


    render(){
        return (
          <div className="active-dark">
            <React.Fragment>
              <PageHelmet pageId={25} />

              <Header
                headertransparent="header--transparent"
                colorblack="color--black"
                logoname="logo.png"
              />

              {/* Start Breadcrump Area */}
              <Breadcrumb title={"Contatti"} />
              {/* End Breadcrump Area */}

              {/* Start Contact Page Area  */}
              <div className="rn-contact-page ptb--120 bg_color--1">
                <ContactTwo />
              </div>
              {/* End Contact Page Area  */}

              {/* Start Contact Top Area  */}
              <div className="rn-contact-top-area ptb--120 bg_color--5">
                <div className="container">
                  <div className="row">
                    {/* Start Single Address  */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="rn-address">
                        <div className="icon">
                          <FiHeadphones />
                        </div>
                        <div className="inner">
                          <h4
                            className="title"
                            style={{
                              color: "#000",
                            }}
                          >
                            Telefono
                          </h4>
                          <p>
                            <a href="tel:+039068601960">06 860 1960</a> <br />
                            <a
                              href="tel:+0393392528986"
                              style={{
                                color: "red",
                              }}
                            >
                              339 2528 986
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Address  */}

                    {/* Start Single Address  */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                      <div className="rn-address">
                        <div className="icon">
                          <FiMail />
                        </div>
                        <div className="inner">
                          <h4
                            className="title"
                            style={{
                              color: "#000",
                            }}
                          >
                            Email
                          </h4>
                          <p>
                            <a href="mailto:posta@angelomontini.com">
                              posta@angelomontini.com
                            </a>
                          </p>
                          <p>
                            <a href="mailto:ciao@angelomontini.com">
                              ciao@angelomontini.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Address  */}

                    {/* Start Single Address  */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                      <div className="rn-address">
                        <div className="icon">
                          <FiMapPin />
                        </div>
                        <div className="inner">
                          <h4
                            className="title"
                            style={{
                              color: "#000",
                            }}
                          >
                            Sede
                          </h4>
                          <p>
                            Via Portuense
                            <br />{" "}
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              00148 Roma
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Address  */}
                  </div>
                </div>
              </div>
              {/* End Contact Top Area  */}

              {/* Start Brand Area */}
              <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <BrandTwo />
                    </div>
                  </div>
                </div>
              </div>
              {/* End Brand Area */}

              {/* Start Back To Top */}
              <div className="backto-top">
                <ScrollToTop showUnder={160}>
                  <FiChevronUp />
                </ScrollToTop>
              </div>
              {/* End Back To Top */}

              <Footer />
            </React.Fragment>
          </div>
        );
    }
}
export default Contact
import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import {
  FiChevronUp,
  FiCheck 
} from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Header from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/header/Header.jsx";
import Footer from "../component/footer/Footer";
import { supabase } from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/system/supaclient.js";

class Prezzo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prezzoList1: [],
      prezzoList2: [],
      prezzoList3: [],
      prezzoList4: [],
      prezziData: [], // Cambiato qui
    };
  }



  componentDidMount() {
    this.fetchprezziData();
  }

  fetchprezziData = async () => {
    const { data: prezziData, error: prezziError } = await supabase
      .from("pagServizi")
      .select("*");

    if (prezziError) {
      console.log(
        "Errore nel caricamento dei dati dalla tabella 'home':",
        prezziError
      );
      return;
    }

    if (prezziData) {
      this.setState({ prezziData: prezziData }); // Aggiornato qui
    }

    try {
      const promises = [1, 2, 3, 4].map((sezione) =>
        supabase
          .from("prezzi")
          .select("*")
          .eq("sezione", sezione)
          .order("order", { ascending: true })
      );
      const results = await Promise.all(promises);

      this.setState({
        prezzoList1: results[0].data,
        prezzoList2: results[1].data,
        prezzoList3: results[2].data,
        prezzoList4: results[3].data,
      });
    } catch (error) {
      console.log("Errore nel caricamento dei dati:", error);
    }
  };

  render() {
    const prezziDataWithID6 = this.state.prezziData.find(
      (item) => item.id === 6
    );
      const prezziDataWithID7 = this.state.prezziData.find(
        (item) => item.id === 7
      );
        const prezziDataWithID8 = this.state.prezziData.find(
          (item) => item.id === 8
        );
    return (
      <div className="active-dark">
        <React.Fragment>
          <PageHelmet pageId={28} />
          <Header
            headertransparent="header--transparent"
            colorblack="color--black"
            logoname="logo.png"
          />

          {/* Start Breadcrump Area */}
          <Breadcrumb title={"prezzi"} />
          {/* End Breadcrump Area */}

          {/* Start Page Wrapper  */}
          <main className="page-wrapper">
            {/* Start Pricing Tbale Area  */}
            <div className="rn-pricing-table-area ptb--120 bg_color--5">
              <div className="container">
                <div id="svi" className="col-lg-12">
                  <div className="section-title text-center mb--30">
                    <h2 className="title">
                      {prezziDataWithID7 ? prezziDataWithID7.titolo : ""}
                    </h2>
                    <p className="description">
                      {prezziDataWithID7 ? prezziDataWithID7.descrizione : ""}
                    </p>
                  </div>
                </div>
                <div className="row">
                  {this.state.prezzoList2.map((val, i) => (
                    <div className="col-lg-4 col-md-6 col-12 mb--20" key={i}>
                      <div className="rn-pricing">
                        <div className="pricing-table-inner">
                          <div className="pricing-header">
                            <h4 className="title">{val.title}</h4>
                            <div className="pricing">
                              <span className="price">{val.prezzo}</span>
                              <span className="subtitle">
                                {val.description}
                              </span>
                            </div>
                          </div>
                          <div className="pricing-body">
                            <ul className="list-style--1">
                              <li>
                                <FiCheck /> {val.body}
                              </li>
                            </ul>
                          </div>
                          <div className="pricing-footer">
                            <a className="rn-btn" href={val.link}>
                              Acquista
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div id="hos" className="col-lg-12">
                  <div className="section-title text-center mt--30 mb--30">
                    <h2 className="title">
                      {prezziDataWithID6 ? prezziDataWithID6.titolo : ""}
                    </h2>
                    <p className="description">
                      {prezziDataWithID6 ? prezziDataWithID6.descrizione : ""}
                    </p>
                  </div>
                </div>
                <div className="row">
                  {this.state.prezzoList1.map((val, i) => (
                    <div className="col-lg-4 col-md-6 col-12 mb--20" key={i}>
                      <div className="rn-pricing">
                        <div className="pricing-table-inner">
                          <div className="pricing-header">
                            <h4 className="title">{val.title}</h4>
                            <div className="pricing">
                              <span className="price">{val.prezzo}</span>
                              <span className="subtitle">
                                {val.description}
                              </span>
                            </div>
                          </div>
                          <div className="pricing-body">
                            <ul className="list-style--1">
                              <li>
                                <FiCheck /> {val.body}
                              </li>
                            </ul>
                          </div>
                          <div className="pricing-footer">
                            <a className="rn-btn" href={val.link}>
                              Acquista
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-lg-12">
                  <div className="section-title text-center mt--30 mb--30">
                    <h2 className="title">
                      {prezziDataWithID8 ? prezziDataWithID8.titolo : ""}
                    </h2>
                    <p className="description">
                      {prezziDataWithID8 ? prezziDataWithID8.descrizione : ""}
                    </p>
                  </div>
                </div>
                <div className="row">
                  {this.state.prezzoList3.map((val, i) => (
                    <div className="col-lg-4 col-md-6 col-12 mb--20" key={i}>
                      <div className="rn-pricing">
                        <div className="pricing-table-inner">
                          <div className="pricing-header">
                            <h4 className="title">{val.title}</h4>
                            <div className="pricing">
                              <span className="price">{val.prezzo}</span>
                              <span className="subtitle">
                                {val.description}
                              </span>
                            </div>
                          </div>
                          <div className="pricing-body">
                            <ul className="list-style--1">
                              <li>
                                <FiCheck /> {val.body}
                              </li>
                            </ul>
                          </div>
                          <div className="pricing-footer">
                            <a className="rn-btn" href={val.link}>
                              Acquista
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* End Pricing Tbale Area  */}
          </main>
          {/* End Page Wrapper  */}

          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
          {/* End Back To Top */}

          <Footer />
        </React.Fragment>
      </div>
    );
  }
}
export default Prezzo;

import React, { Component } from "react";
import {FaLinkedinIn} from "react-icons/fa";
import Assistant from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/footer/assistant-ai.jsx";

const SocialShare = [
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/company/agenzia-montini/",
  },
];

class Footer extends Component{
    render(){
        return (
          <React.Fragment>
            <footer className="footer-area">
              <div className="footer-wrapper">
                <div className="row align-items-end row--0">
                  <div className="col-lg-6">
                    <div className="footer-left">
                      <div className="inner">
                        <span>Assistenza clienti</span>
                        <h3 style={{color:'#fff'}}>
                          Usa il nostro chatbot AI per ottenere risposte rapide
                          e personalizzate alle tue domande. <br />
                          <br />
                          Trovi il bottone in basso a destra.
                        </h3>
                        <Assistant />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="footer-right" data-black-overlay="6">
                      <div className="row">
                        {/* Start Single Widget  */}
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="footer-link">
                            <h4>Utilità</h4>
                            <ul className="ft-link">
                              <li>
                                <a href="/servizi/privacy">Privacy Policy</a>
                              </li>
                              <li>
                                <a href="/servizi/app-privacy">
                                  Privacy Assistente Virtuale
                                </a>
                              </li>
                              <li>
                                <a href="/servizi/termini">
                                  Termini di utilizzo Assistente Virtuale
                                </a>
                              </li>
                              <li>
                                <a href="/servizi/cookies">Cookies Policy</a>
                              </li>
                              <li>
                                <a href="/contact">Contatti</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* End Single Widget  */}
                        {/* Start Single Widget  */}
                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                          <div className="footer-link">
                            <h4>Scrivici</h4>
                            <ul className="ft-link">
                              <li>
                                <a href="mailto:posta@angelomontini.com">
                                  posta@angelomontini.com
                                </a>
                              </li>
                              <li>
                                <a href="mailto:ciao@angelomontini.com">
                                  ciao@angelomontini.com
                                </a>
                              </li>
                            </ul>

                            <div className="social-share-inner">
                              <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                {SocialShare.map((val, i) => (
                                  <li key={i}>
                                    <a
                                      href={`${val.link}`}
                                      style={{
                                        background: "#0967c2",
                                        textAlign: "center",
                                        color: "#fff",
                                      }}
                                    >
                                      {val.Social}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* End Single Widget  */}

                        <div className="col-lg-12">
                          <div className="copyright-text">
                            <p>
                              Copyright © 2023. Angelo Montini P.iva.
                              11822551005. All Rights Reserved.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </React.Fragment>
        );
    }
}
export default Footer;
import React from "react";

const DialogflowMessenger = () => {

  return (
    <div>
      <df-messenger
        project-id="ghost-first"
        agent-id="de85ae2b-e861-4b2d-a088-009e7fafb9e8"
        language-code="it"
        max-query-length="-1"
     
      >
        <df-messenger-chat-bubble
          chat-title="Assistente Virtuale"
          chat-subtitle="Informazioni sulle attività dell'Agenzia"
          chat-icon="https://istitutometaform.it/wp-content/uploads/2024/09/bot.png"
        ></df-messenger-chat-bubble>
      </df-messenger>

      {/* Stile per posizionare il widget di Dialogflow Messenger */}
    </div>
  );
};

export default DialogflowMessenger;

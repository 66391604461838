import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/header/Header.jsx";
import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";

import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import Bloghome from "../elements/Bloghome";
import BrandTwo from "../elements/BrandTwo";
import PageHelmet from "../component/common/Helmet";

 

class MainDemo extends Component{
    render(){
     
        return (
          <div className="active-dark">
            <PageHelmet pageId={1} />
            
            <Header
              headertransparent="header--transparent"
              colorblack="color--black"
              logoname="logo.png"
            />

            {/* Start Slider Area   */}
            <div className="slider-wrapper">
            
            <SliderOne />
            </div>
            {/* End Slider Area   */}

            {/* Start About Area */}

            <About />

            {/* End About Area */}

            {/* Start Service Area  */}
            <div className="service-area ptb--80  bg_image bg_image--3">
              <div className="container">
                <ServiceTwo />
              </div>
            </div>
            {/* End Service Area  */}

            {/* Start Portfolio Area */}
            <div className="portfolio-area ptb--120 bg_color--1">
              <div className="portfolio-sacousel-inner mb--55">
                <Portfolio />
              </div>
            </div>
            {/* End Portfolio Area */}

            {/* Start CounterUp Area */}
            <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title text-center">
                      <h3 className="fontWeight500">Numeri</h3>
                    </div>
                  </div>
                </div>
                <CounterOne />
              </div>
            </div>
            {/* End CounterUp Area */}

            {/* Start Blog Area */}
            <div className="portfolio-area ptb--120 bg_color--1">
              <div className="portfolio-sacousel-inner mb--55">
                <Bloghome />
              </div>
            </div>
            {/* End Blog Area */}

            {/* Start Brand Area */}
            <div className="rn-brand-area brand-separation pb--120">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <BrandTwo />
                  </div>
                </div>
              </div>
            </div>
            {/* End Brand Area */}
            {/* Start Back To Top */}
            <div className="backto-top">
              <ScrollToTop showUnder={160}>
                <FiChevronUp />
              </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />
          </div>
        );
    }
}
export default MainDemo;
import React, { Component } from "react";
import PageHelmet from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/common/Helmet.jsx";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/header/Header.jsx";
import Footer from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/component/footer/Footer.jsx";
import ServiceTwo from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/elements/service/ServiceTwo.jsx";


class Privacy extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageId={26} />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image"
          style={{
            opacity: "1",
            backgroundImage: `url("/assets/images/bg/12.png")`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inner rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Privacy Policy</h2>
                  <ul className="page-list">
                    <li className="breadcrumb-item">
                      <Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
                    </li>

                    <li className="breadcrumb-item active"> Privacy Policy</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-12 col-12">
                        <div class="details mt_md--30 mt_sm--30">
                          <div>
                            <p>
                              <strong>
                                Informativa ai sensi del Regolamento UE 2016/679
                              </strong>
                            </p>
                            <p>
                              Ai sensi dell’art. 13 del Regolamento UE 2016/679
                              (in seguito anche Regolamento), Angelo Montini
                              p.iva 11822551005 CF MNTNGL73C16H501P, in qualità
                              di Titolare del trattamento, la Informa che potrà
                              trattare i suoi dati personali per fornirle
                              assistenza e rispondere alle sue richieste. Il
                              conferimento dei dati è necessario e in sua
                              mancanza il Titolare non potrà rispondere alle sue
                              richieste. Il trattamento sarà effettuato con
                              strumenti automatizzati e manuali attraverso
                              misure di sicurezza tali da garantire la tutela e
                              la massima riservatezza dei dati. La base
                              giuridica di tale trattamento si fonda
                              sull’esecuzione dei servizi a favore dell’utente.
                              I dati personali verranno conservati per il tempo
                              necessario all’evasione della sua richiesta. I
                              Suoi dati, che non saranno diffusi, potranno
                              essere comunicati a società terze per la
                              realizzazione di attività strumentali alla
                              realizzazione delle finalità su menzionate e a
                              soggetti cui la facoltà di accedere ai dati sia
                              riconosciuta da disposizioni di legge e/o di
                              normativa secondaria.
                            </p>
                            <p>
                              La informiamo, infine, che potrà esercitare i
                              diritti contemplati dal Regolamento, scrivendo a{" "}
                              <a href="mailto:posta@angelomontini.com">
                                posta@angelomontini.com
                              </a>{" "}
                              In questa pagina si descrivono le modalità di
                              gestione del sito internet{" "}
                              <a href="https://www.angelomontini.com">
                                https://www.angelomontini.com
                              </a>
                            </p>
                            <p>
                              In riferimento al trattamento dei dati personali
                              degli utenti che lo consultano. Si tratta di
                              un'informativa che è resa ai sensi del Regolamento
                              Europeo 2016/679 dettato in materia di protezione
                              delle persone fisiche con riguardo al trattamento
                              dei dati personali, nonché alla libera
                              circolazione di tali dati (di seguito anche
                              Regolamento) per gli utenti dei servizi del nostro
                              sito, che vengono erogati via internet.
                              L'informativa non è valida per altri siti web
                              eventualmente consultabili attraverso i nostri
                              link, di cui il responsabile del sito web non è in
                              alcun modo responsabile.
                            </p>
                            <p>IL TITOLARE DEL TRATTAMENTO</p>
                            <p>
                              Il Titolare del trattamento dei dati personali,
                              relativi a persone identificate o identificabili
                              trattati a seguito della consultazione del nostro
                              sito è Angelo Montini P.iva 11822551005 CF
                              MNTNGL73C16H501P Via Portuense 743 00148 Roma ph.
                              339 2528986
                            </p>
                            <p>LUOGO, FINALITÀ E COMUNICAZIONE DEI DATI</p>
                            <p>
                              I trattamenti connessi ai servizi web di questo
                              sito hanno luogo presso la predetta sede della
                              società e sono curati da personale incaricato del
                              trattamento. I dati possono essere trattati da
                              società che per nostro conto si occupano della
                              gestione informatica (compreso l’hosting) del
                              sito. I dati personali forniti dagli utenti che
                              inoltrano richieste di invio di materiale
                              informativo o altre comunicazioni sono utilizzati
                              al solo fine di eseguire il servizio o la
                              prestazione richiesta e sono comunicati a terzi
                              nel solo caso in cui ciò sia a tal fine
                              necessario.
                            </p>
                            <p>
                              TIPI DI DATI TRATTATI Dati di navigazione I
                              sistemi informatici e le procedure software
                              preposte al funzionamento di questo sito web
                              acquisiscono, nel corso del loro normale
                              esercizio, alcuni dati personali la cui
                              trasmissione è implicita nell'uso dei protocolli
                              di comunicazione di Internet. Si tratta di
                              informazioni che non sono raccolte per essere
                              associate a interessati identificati, ma che per
                              loro stessa natura potrebbero, attraverso
                              elaborazioni ed associazioni con dati detenuti da
                              terzi, permettere di identificare gli utenti. In
                              questa categoria di dati rientrano gli indirizzi
                              IP o i nomi a dominio dei computer utilizzati
                              dagli utenti che si connettono al sito, gli
                              indirizzi in notazione URI (Uniform Resource
                              Identifier) delle risorse richieste, l'orario
                              della richiesta, il metodo utilizzato nel
                              sottoporre la richiesta al server, la dimensione
                              del file ottenuto in risposta, il codice numerico
                              indicante lo stato della risposta data dal server
                              (buon fine, errore, ecc.) ed altri parametri
                              relativi al sistema operativo e all'ambiente
                              informatico dell'utente. Questi dati vengono
                              utilizzati al solo fine di ricavare informazioni
                              statistiche anonime sull'uso del sito e per
                              controllarne il corretto funzionamento e vengono
                              cancellati immediatamente dopo l'elaborazione. I
                              dati potrebbero essere utilizzati per
                              l'accertamento di responsabilità in caso di
                              ipotetici reati informatici ai danni del sito.
                              Dati forniti volontariamente dall'utente L'invio
                              facoltativo, esplicito e volontario di posta
                              elettronica agli indirizzi indicati su questo sito
                              comporta la successiva acquisizione dell'indirizzo
                              del mittente, necessario per rispondere alle
                              richieste, nonché degli eventuali altri dati
                              personali inseriti nella missiva. Specifiche
                              informative di sintesi verranno progressivamente
                              riportate o visualizzate nelle pagine del sito
                              predisposte per particolari servizi a richiesta.
                            </p>
                            <p>
                              POLITICA DEI COOKIES Il presente sito web utilizza
                              cookie e tecnologie simili per garantire il
                              corretto funzionamento delle procedure e
                              migliorare l'esperienza di uso delle applicazioni
                              online. Per cookies si intende un elemento
                              testuale che viene inserito nel disco fisso di un
                              computer. I cookies hanno la funzione di snellire
                              l’analisi del traffico su web o di segnalare
                              quando un sito specifico viene visitato e
                              consentono alle applicazioni web di inviare
                              informazioni a singoli utenti. Di seguito si
                              forniscono informazioni dettagliate sull'uso dei
                              cookie e di tecnologie similari, su come sono
                              utilizzati e su come gestirli. Il sito web
                              utilizza: Cookie tecnici: tali cookie sono
                              indispensabili per il corretto funzionamento di un
                              sito web che vengono utilizzati per gestire
                              diversi servizi legati ai siti web (come ad
                              esempio un login o l’accesso alle funzioni
                              riservate nei siti). La durata dei cookie è
                              strettamente limitata alla sessione di lavoro
                              oppure possono avvalersi di un tempo di permanenza
                              più lungo al fine di ricordare le scelte del
                              visitatore. La disattivazione dei cookie
                              strettamente necessari può compromettere
                              l’esperienza d’uso e di navigazione del sito web.
                            </p>
                            <p>DISABILITAZIONE TOTALE O PARZIALE DEI COOKIES</p>
                            <p>
                              La disabilitazione totale o parziale dei cookies
                              tecnici può compromettere l'utilizzo delle
                              funzionalità del sito riservate agli utenti
                              registrati. Al contrario, la fruibilità dei
                              contenuti pubblici è possibile anche disabilitando
                              completamente i cookies. Di seguito indichiamo
                              come procedere alla disabilitazione dei cookies
                              attraverso alcuni browser.
                            </p>
                            <p>
                              <em>Google Chrome </em>
                            </p>
                            <p>
                              Seleziona l'icona del menu Chrome . Seleziona
                              Impostazioni. Nella parte inferiore della pagina,
                              seleziona Mostra impostazioni avanzate. Nella
                              sezione "Privacy", seleziona Impostazioni
                              contenuti. Seleziona Impedisci ai siti di
                              impostare dati. Seleziona Fine.
                            </p>
                            <p>
                              <em>Safari</em>
                            </p>
                            <p>
                              Andare al menu Safari (icona in alto a destra del
                              browser) e selezionare Preferenze. Nella finestra
                              a comparsa che si apre, selezionare l’icona
                              Sicurezza (a forma di lucchetto). Sotto la voce
                              "Accetta cookie", selezionare il pulsante "Mai".
                            </p>
                            <p>
                              <em>Firefox</em>
                            </p>
                            <p>
                              Fare clic sul pulsante dei menu e selezionare
                              Opzioni. Selezionare il pannello Privacy. Alla
                              voce Impostazioni cronologia: selezionare utilizza
                              impostazioni personalizzate. Per attivare i
                              cookie, contrassegnare la voce Accetta i cookie
                              dai siti; per disattivarli togliere il
                              contrassegno dalla voce.
                            </p>
                            <p>
                              <em>Explorer </em>
                            </p>
                            <p>
                              Andare al menu di Explorer e fare clic sul
                              pulsante Impostazioni e quindi su Opzioni
                              Avanzate. Fare clic sulla scheda Cookie spostare
                              il dispositivo di scorrimento verso l'alto per
                              bloccare tutti i cookie oppure verso il basso per
                              consentirli tutti e quindi fare clic su OK.
                            </p>
                            <p>FACOLTATIVITÀ DEL CONFERIMENTO DEI DATI</p>
                            <p>
                              A parte quanto specificato per i dati di
                              navigazione, l’utente è libero di fornire i dati
                              personali per richiedere i servizi offerti dalla
                              società. Il loro mancato conferimento può
                              comportare l’impossibilità di ottenere il servizio
                              richiesto. MODALITÀ DI TRATTAMENTO, conservazione
                              DEI DATI e base giuridica I dati personali sono
                              trattati con strumenti manuali e automatizzati, in
                              ottemperanza alle prescrizioni del Regolamento. I
                              dati saranno conservati solo per il tempo
                              necessario a conseguire lo scopo per il quale sono
                              stati raccolti, salve esigenze diverse derivanti
                              da obblighi di legge. In seguito, i dati personali
                              saranno cancellati. I dati personali oggetto di
                              trattamento saranno custoditi in modo da ridurre
                              al minimo, mediante l'adozione di idonee e
                              preventive misure di sicurezza, i rischi di
                              distruzione o perdita, anche accidentale, dei dati
                              stessi, di accesso non autorizzato o di
                              trattamento non consentito o non conforme alle
                              finalità della raccolta. La base giuridica del
                              trattamento dei dati personali è l’erogazione di
                              un servizio o il riscontro ad una richiesta a
                              favore dell’interessato.
                            </p>
                            <p>
                              DIRITTI DEGLI INTERESSATI In relazione ai
                              trattamenti dei suoi dati personali svolti tramite
                              questo sito, in ogni momento, in qualità di
                              interessato potrà esercitare i diritti previsti
                              dal Regolamento UE 2016/679 scrivendo
                              all’indirizzo e-mail{" "}
                              <a href="mailto:posta@angelomontini.com">
                                posta@angelomontini.com
                              </a>{" "}
                              In particolare, potrà:
                            </p>
                            <ul>
                              <li>
                                accedere ai suoi dati personali, ottenendo
                                evidenza delle finalità perseguite da parte del
                                Titolare, delle categorie di dati coinvolti, dei
                                destinatari a cui gli stessi possono essere
                                comunicati, del periodo di conservazione
                                applicabile, dell’esistenza di processi
                                decisionali automatizzati, compresa la
                                profilazione, e, almeno in tali casi,
                                informazioni significative sulla logica
                                utilizzata, nonché l'importanza e le conseguenze
                                possibili per l'interessato, ove non già
                                indicato nel testo di questa Informativa;
                              </li>
                              <li>
                                ottenere senza ritardo la rettifica dei dati
                                personali inesatti che la riguardano;
                              </li>
                              <li>
                                ottenere, nei casi previsti dalla legge, la
                                cancellazione dei suoi dati;
                              </li>
                              <li>
                                ottenere la limitazione del trattamento o di
                                opporsi allo stesso, quando ammesso in base alle
                                previsioni di legge applicabili al caso
                                specifico; ● ove lo ritenga opportuno, proporre
                                reclamo all'autorità di controllo.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Service Area  */}
        <div className="service-area ptb--80  bg_image bg_image--3">
          <div className="container">
            <ServiceTwo />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}

 export default Privacy;

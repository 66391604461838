import React, { Component } from "react";
import { supabase } from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/system/supaclient.js";


class ServiceOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderList: [],
    };
  }

  componentDidMount() {
    this.fetchPortfolioData();
  }

  fetchPortfolioData = async () => {
    const { data, error } = await supabase.from("slider").select("*");
    if (error) {
      console.log("Errore nel caricamento dei dati:", error);
      return;
    }
    if (data) {
      this.setState({ sliderList: data });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="row">
          {this.state.sliderList.map((val, i) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
              <div className="service service__style--1">
                <div className="icon">
                  <img
                    src={`/assets/images/icons/icon-${val.icon}.png`}
                    alt="Digital Agency"
                  />
                </div>
                <div className="content">
                  <h4 className="title">{val.title}</h4>
                  <p>
                    {val.description} <a href={`${val.category}`}> <b>Scopri di più</b></a>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceOne;
import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import { supabase } from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/system/supaclient.js";


class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolioList: [],
      homeData: []
    };
  }
 
  componentDidMount() {
    this.fetchPortfolioData();
  }

  fetchPortfolioData = async () => {
    const { data: homeData, error: homeError } = await supabase
      .from("home")
      .select("*");

    if (homeError) {
      console.log(
        "Errore nel caricamento dei dati dalla tabella 'home':",
        homeError
      );
      return;
    }

    if (homeData) {
      this.setState({ homeData: homeData }); // Aggiornato qui
    }
 
    const { data, error } = await supabase.from("portfolio").select("*").eq("evidenza", true).order("quando", { ascending: true });
    if (error) {
      console.log("Errore nel caricamento dei dati:", error);
      return;
    }
    if (data) {
      this.setState({ portfolioList: data });
    }
  };

  render() {
     const homeDataWithID1 = this.state.homeData.find(
       (item) => item.id === 1
     );
    return (
      <React.Fragment>
        <div className="portfolio-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title">
                  <h2> {homeDataWithID1 ? homeDataWithID1.titolo : ""}</h2>
                  <p>{homeDataWithID1 ? homeDataWithID1.descrizione : ""}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="portfolio-slick-activation mt--70 mt_sm--40">
            <Slider {...portfolioSlick2}>
              {this.state.portfolioList.map((value, index) => (
                <div className="portfolio" key={index}>
                  <div className="thumbnail-inner">
                    <div
                      className="thumbnail"
                      style={{
                        backgroundImage: `url(${value.copertina})`,
                      }}
                    ></div>
                    <div
                      className="bg-blr-image"
                      style={{
                        backgroundImage: `url(${value.copertina})`,
                      }}
                    ></div>
                  </div>
                  <div className="content">
                    <div className="inner">
                      <p>{value.category}</p>
                      <h4 className="title">
                        <a href={value.link}>{value.title}</a>
                      </h4>
                      <ul>
                        <li>
                          <p>{value.quando}</p>
                        </li>
                        <li>
                          <p>{value.dove}</p>
                        </li>
                        <li>
                          <p>{value.settore}</p>
                        </li>
                      </ul>
                      <div className="portfolio-button">
                        <a className="rn-btn" href={value.link}>
                          Link
                        </a>
                      </div>
                    </div>
                  </div>
           
                  <a
                    className="link-overlay"
                    href={value.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                  
                  </a>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Portfolio;